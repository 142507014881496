import { LogoProps } from '../types'
import LogoContainer from '../LogoContainer'

export const BaseIcon: React.FunctionComponent<LogoProps> = ({
  iconGradientStart,
  iconGradientStop,
  ...rest
}) => {
  return (
    <LogoContainer
      iconGradientStart={iconGradientStart}
      iconGradientStop={iconGradientStop}
      {...rest}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="211.199"
        height="211.199"
        viewBox="0 0 211.199 211.199"
      >
        <g
          id="Group_444"
          data-name="Group 444"
          transform="translate(-905.734 -575.9)"
        >
          <path
            id="Path_777"
            data-name="Path 777"
            d="M0,73.215C0,38.7,0,21.444,10.722,10.722S38.7,0,73.215,0h64.768c34.514,0,51.771,0,62.493,10.722S211.2,38.7,211.2,73.215v64.768c0,34.514,0,51.771-10.722,62.493S172.5,211.2,137.983,211.2H73.215c-34.514,0-51.771,0-62.493-10.722S0,172.5,0,137.983Z"
            transform="translate(905.734 575.9)"
            fill="#0052cc"
          />
          <path
            id="Path_779"
            data-name="Path 779"
            d="M5639.1,410.447a1.407,1.407,0,0,1,0-1.04,69.587,69.587,0,0,1,12.156-19.364,1.373,1.373,0,0,1,2.309.4l20.539,51.8a1.284,1.284,0,0,0,2.383.01l26.508-65.4a1.373,1.373,0,0,1,2.543,0l9.125,22.62L5675.8,491.015a1.368,1.368,0,0,1-2.52,0Z"
            transform="translate(-4700.391 247.719)"
            fill="#fff"
          />
          <path
            id="Path_778"
            data-name="Path 778"
            d="M5836,429.588,5811.637,400.7h0l-2.34-2.836-.824,2.065c-.016.028-.027.056-.043.085l-20.77,51.677a1.282,1.282,0,0,1-2.383-.017l-11.7-29.872a1.371,1.371,0,0,0-2.539-.045l-10.125,23.688a1.287,1.287,0,0,0-.008.986l23.168,54.28a1.375,1.375,0,0,0,2.527,0l29.063-68.389a2.569,2.569,0,1,1,4.746,1.966l-3.434,8.3.27-.034,13.934-1.675A7.088,7.088,0,0,0,5836,429.588Z"
            transform="translate(-4754.355 238.032)"
            fill="#fff"
          />
        </g>
      </svg>
    </LogoContainer>
  )
}
