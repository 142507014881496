import React from 'react'
import ActivityIcon from '@atlaskit/icon/glyph/activity'
import AddCircleIcon from '@atlaskit/icon/glyph/add-circle'
import AddIcon from '@atlaskit/icon/glyph/add'
import AddItemIcon from '@atlaskit/icon/glyph/add-item'
import AddonIcon from '@atlaskit/icon/glyph/addon'
import AppAccessIcon from '@atlaskit/icon/glyph/app-access'
import AppSwitcherIcon from '@atlaskit/icon/glyph/app-switcher'
import ArchiveIcon from '@atlaskit/icon/glyph/archive'
import ArrowDownCircleIcon from '@atlaskit/icon/glyph/arrow-down-circle'
import ArrowDownIcon from '@atlaskit/icon/glyph/arrow-down'
import ArrowLeftCircleIcon from '@atlaskit/icon/glyph/arrow-left-circle'
import ArrowLeftIcon from '@atlaskit/icon/glyph/arrow-left'
import ArrowRightCircleIcon from '@atlaskit/icon/glyph/arrow-right-circle'
import ArrowRightIcon from '@atlaskit/icon/glyph/arrow-right'
import ArrowUpCircleIcon from '@atlaskit/icon/glyph/arrow-up-circle'
import ArrowUpIcon from '@atlaskit/icon/glyph/arrow-up'
import AttachmentIcon from '@atlaskit/icon/glyph/attachment'
import AudioCircleIcon from '@atlaskit/icon/glyph/audio-circle'
import AudioIcon from '@atlaskit/icon/glyph/audio'
import BacklogIcon from '@atlaskit/icon/glyph/backlog'
import BillingFilledIcon from '@atlaskit/icon/glyph/billing-filled'
import BillingIcon from '@atlaskit/icon/glyph/billing'
import BoardIcon from '@atlaskit/icon/glyph/board'
import BookIcon from '@atlaskit/icon/glyph/book'
import BulletListIcon from '@atlaskit/icon/glyph/bullet-list'
import CalendarFilledIcon from '@atlaskit/icon/glyph/calendar-filled'
import CalendarIcon from '@atlaskit/icon/glyph/calendar'
import CameraFilledIcon from '@atlaskit/icon/glyph/camera-filled'
import CameraRotateIcon from '@atlaskit/icon/glyph/camera-rotate'
import CameraTakePictureIcon from '@atlaskit/icon/glyph/camera-take-picture'
import CameraIcon from '@atlaskit/icon/glyph/camera'
import CanvasIcon from '@atlaskit/icon/glyph/canvas'
import CheckCircleOutlineIcon from '@atlaskit/icon/glyph/check-circle-outline'
import CheckCircleIcon from '@atlaskit/icon/glyph/check-circle'
import CheckboxIcon from '@atlaskit/icon/glyph/checkbox'
import CheckboxIndeterminateIcon from '@atlaskit/icon/glyph/checkbox-indeterminate'
import CheckIcon from '@atlaskit/icon/glyph/check'
import ChevronDownCircleIcon from '@atlaskit/icon/glyph/chevron-down-circle'
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down'
import ChevronLeftCircleIcon from '@atlaskit/icon/glyph/chevron-left-circle'
import ChevronLeftLargeIcon from '@atlaskit/icon/glyph/chevron-left-large'
import ChevronLeftIcon from '@atlaskit/icon/glyph/chevron-left'
import ChevronRightCircleIcon from '@atlaskit/icon/glyph/chevron-right-circle'
import ChevronRightLargeIcon from '@atlaskit/icon/glyph/chevron-right-large'
import ChevronRightIcon from '@atlaskit/icon/glyph/chevron-right'
import ChevronUpCircleIcon from '@atlaskit/icon/glyph/chevron-up-circle'
import ChevronUpIcon from '@atlaskit/icon/glyph/chevron-up'
import ChildIssuesIcon from '@atlaskit/icon/glyph/child-issues'
import CodeIcon from '@atlaskit/icon/glyph/code'
import CommentIcon from '@atlaskit/icon/glyph/comment'
import ComponentIcon from '@atlaskit/icon/glyph/component'
import CopyIcon from '@atlaskit/icon/glyph/copy'
import CreditcardFilledIcon from '@atlaskit/icon/glyph/creditcard-filled'
import CreditcardIcon from '@atlaskit/icon/glyph/creditcard'
import CrossCircleIcon from '@atlaskit/icon/glyph/cross-circle'
import CrossIcon from '@atlaskit/icon/glyph/cross'
import DashboardIcon from '@atlaskit/icon/glyph/dashboard'
import DecisionIcon from '@atlaskit/icon/glyph/decision'
import DepartmentIcon from '@atlaskit/icon/glyph/department'
import DetailViewIcon from '@atlaskit/icon/glyph/detail-view'
import DiscoverFilledIcon from '@atlaskit/icon/glyph/discover-filled'
import DiscoverIcon from '@atlaskit/icon/glyph/discover'
import DocumentFilledIcon from '@atlaskit/icon/glyph/document-filled'
import DocumentIcon from '@atlaskit/icon/glyph/document'
import DocumentsIcon from '@atlaskit/icon/glyph/documents'
import DownloadIcon from '@atlaskit/icon/glyph/download'
import DragHandlerIcon from '@atlaskit/icon/glyph/drag-handler'
import DropboxIcon from '@atlaskit/icon/glyph/dropbox'
import EditFilledIcon from '@atlaskit/icon/glyph/edit-filled'
import EditIcon from '@atlaskit/icon/glyph/edit'
import EmailIcon from '@atlaskit/icon/glyph/email'
import EmojiAddIcon from '@atlaskit/icon/glyph/emoji-add'
import EmojiIcon from '@atlaskit/icon/glyph/emoji'
import ErrorIcon from '@atlaskit/icon/glyph/error'
import ExportIcon from '@atlaskit/icon/glyph/export'
import FeedbackIcon from '@atlaskit/icon/glyph/feedback'
import FileIcon from '@atlaskit/icon/glyph/file'
import FilterIcon from '@atlaskit/icon/glyph/filter'
import FlagFilledIcon from '@atlaskit/icon/glyph/flag-filled'
import FolderFilledIcon from '@atlaskit/icon/glyph/folder-filled'
import FolderIcon from '@atlaskit/icon/glyph/folder'
import FollowersIcon from '@atlaskit/icon/glyph/followers'
import FollowingIcon from '@atlaskit/icon/glyph/following'
import GoogledriveIcon from '@atlaskit/icon/glyph/googledrive'
import GraphBarIcon from '@atlaskit/icon/glyph/graph-bar'
import GraphLineIcon from '@atlaskit/icon/glyph/graph-line'
import GsuiteIcon from '@atlaskit/icon/glyph/gsuite'
import HighlightsIcon from '@atlaskit/icon/glyph/highlights'
import HomeCircleIcon from '@atlaskit/icon/glyph/home-circle'
import HomeIcon from '@atlaskit/icon/glyph/home'
import ImageBorderIcon from '@atlaskit/icon/glyph/image-border'
import ImageResizeIcon from '@atlaskit/icon/glyph/image-resize'
import ImageIcon from '@atlaskit/icon/glyph/image'
import InfoIcon from '@atlaskit/icon/glyph/info'
import InviteTeamIcon from '@atlaskit/icon/glyph/invite-team'
import IssueRaiseIcon from '@atlaskit/icon/glyph/issue-raise'
import IssueIcon from '@atlaskit/icon/glyph/issue'
import IssuesIcon from '@atlaskit/icon/glyph/issues'
import LabelIcon from '@atlaskit/icon/glyph/label'
import LightbulbFilledIcon from '@atlaskit/icon/glyph/lightbulb-filled'
import LightbulbIcon from '@atlaskit/icon/glyph/lightbulb'
import LikeIcon from '@atlaskit/icon/glyph/like'
import LinkFilledIcon from '@atlaskit/icon/glyph/link-filled'
import LinkIcon from '@atlaskit/icon/glyph/link'
import ListIcon from '@atlaskit/icon/glyph/list'
import LocationIcon from '@atlaskit/icon/glyph/location'
import LockCircleIcon from '@atlaskit/icon/glyph/lock-circle'
import LockFilledIcon from '@atlaskit/icon/glyph/lock-filled'
import LockIcon from '@atlaskit/icon/glyph/lock'
import MarketplaceIcon from '@atlaskit/icon/glyph/marketplace'
import MentionIcon from '@atlaskit/icon/glyph/mention'
import MenuExpandIcon from '@atlaskit/icon/glyph/menu-expand'
import MenuIcon from '@atlaskit/icon/glyph/menu'
import MobileIcon from '@atlaskit/icon/glyph/mobile'
import MoreVerticalIcon from '@atlaskit/icon/glyph/more-vertical'
import MoreIcon from '@atlaskit/icon/glyph/more'
import NotificationAllIcon from '@atlaskit/icon/glyph/notification-all'
import NotificationDirectIcon from '@atlaskit/icon/glyph/notification-direct'
import NotificationIcon from '@atlaskit/icon/glyph/notification'
import OfficeBuildingFilledIcon from '@atlaskit/icon/glyph/office-building-filled'
import OfficeBuildingIcon from '@atlaskit/icon/glyph/office-building'
import OpenIcon from '@atlaskit/icon/glyph/open'
import OverviewIcon from '@atlaskit/icon/glyph/overview'
import PageFilledIcon from '@atlaskit/icon/glyph/page-filled'
import PageIcon from '@atlaskit/icon/glyph/page'
import PdfIcon from '@atlaskit/icon/glyph/pdf'
import PeopleGroupIcon from '@atlaskit/icon/glyph/people-group'
import PeopleIcon from '@atlaskit/icon/glyph/people'
import PersonCircleIcon from '@atlaskit/icon/glyph/person-circle'
import PersonWithCircleIcon from '@atlaskit/icon/glyph/person-with-circle'
import PersonWithCrossIcon from '@atlaskit/icon/glyph/person-with-cross'
import PersonWithTickIcon from '@atlaskit/icon/glyph/person-with-tick'
import PersonIcon from '@atlaskit/icon/glyph/person'
import PortfolioIcon from '@atlaskit/icon/glyph/portfolio'
import PreferencesIcon from '@atlaskit/icon/glyph/preferences'
import PremiumIcon from '@atlaskit/icon/glyph/premium'
import PresenceActiveIcon from '@atlaskit/icon/glyph/presence-active'
import PresenceBusyIcon from '@atlaskit/icon/glyph/presence-busy'
import PresenceUnavailableIcon from '@atlaskit/icon/glyph/presence-unavailable'
import QuestionCircleIcon from '@atlaskit/icon/glyph/question-circle'
import QuestionIcon from '@atlaskit/icon/glyph/question'
import QuestionsIcon from '@atlaskit/icon/glyph/questions'
import QueuesIcon from '@atlaskit/icon/glyph/queues'
import QuoteIcon from '@atlaskit/icon/glyph/quote'
import RadioIcon from '@atlaskit/icon/glyph/radio'
import RecentIcon from '@atlaskit/icon/glyph/recent'
import RedoIcon from '@atlaskit/icon/glyph/redo'
import RefreshIcon from '@atlaskit/icon/glyph/refresh'
import RetryIcon from '@atlaskit/icon/glyph/retry'
import RoadmapIcon from '@atlaskit/icon/glyph/roadmap'
import RoomMenuIcon from '@atlaskit/icon/glyph/room-menu'
import ScheduleFilledIcon from '@atlaskit/icon/glyph/schedule-filled'
import ScheduleIcon from '@atlaskit/icon/glyph/schedule'
import ScreenIcon from '@atlaskit/icon/glyph/screen'
import SearchIcon from '@atlaskit/icon/glyph/search'
import SelectClearIcon from '@atlaskit/icon/glyph/select-clear'
import SendIcon from '@atlaskit/icon/glyph/send'
import SettingsIcon from '@atlaskit/icon/glyph/settings'
import ShareIcon from '@atlaskit/icon/glyph/share'
import ShipIcon from '@atlaskit/icon/glyph/ship'
import ShortcutIcon from '@atlaskit/icon/glyph/shortcut'
import SignOutIcon from '@atlaskit/icon/glyph/sign-out'
import SignInIcon from '@atlaskit/icon/glyph/sign-in'
import SprintIcon from '@atlaskit/icon/glyph/sprint'
import StarFilledIcon from '@atlaskit/icon/glyph/star-filled'
import StarLargeIcon from '@atlaskit/icon/glyph/star-large'
import StarIcon from '@atlaskit/icon/glyph/star'
import StatusIcon from '@atlaskit/icon/glyph/status'
import StopwatchIcon from '@atlaskit/icon/glyph/stopwatch'
import SubtaskIcon from '@atlaskit/icon/glyph/subtask'
import SuitcaseIcon from '@atlaskit/icon/glyph/suitcase'
import SwitcherIcon from '@atlaskit/icon/glyph/switcher'
import TableIcon from '@atlaskit/icon/glyph/table'
import TaskIcon from '@atlaskit/icon/glyph/task'
import TrashIcon from '@atlaskit/icon/glyph/trash'
import TrayIcon from '@atlaskit/icon/glyph/tray'
import UndoIcon from '@atlaskit/icon/glyph/undo'
import UnlinkIcon from '@atlaskit/icon/glyph/unlink'
import UnlockCircleIcon from '@atlaskit/icon/glyph/unlock-circle'
import UnlockFilledIcon from '@atlaskit/icon/glyph/unlock-filled'
import UnlockIcon from '@atlaskit/icon/glyph/unlock'
import UploadIcon from '@atlaskit/icon/glyph/upload'
import UserAvatarCircleIcon from '@atlaskit/icon/glyph/user-avatar-circle'
import VidAudioMutedIcon from '@atlaskit/icon/glyph/vid-audio-muted'
import VidAudioOnIcon from '@atlaskit/icon/glyph/vid-audio-on'
import VidBackwardIcon from '@atlaskit/icon/glyph/vid-backward'
import VidCameraOffIcon from '@atlaskit/icon/glyph/vid-camera-off'
import VidCameraOnIcon from '@atlaskit/icon/glyph/vid-camera-on'
import VidConnectionCircleIcon from '@atlaskit/icon/glyph/vid-connection-circle'
import VidForwardIcon from '@atlaskit/icon/glyph/vid-forward'
import VidFullScreenOffIcon from '@atlaskit/icon/glyph/vid-full-screen-off'
import VidFullScreenOnIcon from '@atlaskit/icon/glyph/vid-full-screen-on'
import VidHangUpIcon from '@atlaskit/icon/glyph/vid-hang-up'
import VidHdCircleIcon from '@atlaskit/icon/glyph/vid-hd-circle'
import VidPauseIcon from '@atlaskit/icon/glyph/vid-pause'
import VidPlayIcon from '@atlaskit/icon/glyph/vid-play'
import VidRaisedHandIcon from '@atlaskit/icon/glyph/vid-raised-hand'
import VidShareScreenIcon from '@atlaskit/icon/glyph/vid-share-screen'
import VidSpeakingCircleIcon from '@atlaskit/icon/glyph/vid-speaking-circle'
import VidVolumeFullIcon from '@atlaskit/icon/glyph/vid-volume-full'
import VidVolumeHalfIcon from '@atlaskit/icon/glyph/vid-volume-half'
import VidVolumeMutedIcon from '@atlaskit/icon/glyph/vid-volume-muted'
import VideoCircleIcon from '@atlaskit/icon/glyph/video-circle'
import VideoFilledIcon from '@atlaskit/icon/glyph/video-filled'
import WarningIcon from '@atlaskit/icon/glyph/warning'
import WatchFilledIcon from '@atlaskit/icon/glyph/watch-filled'
import WatchIcon from '@atlaskit/icon/glyph/watch'
import WorldIcon from '@atlaskit/icon/glyph/world'
import BitbucketBuildsIcon from '@atlaskit/icon/glyph/bitbucket/builds'

import { IconProps } from './types'

const Icon: React.FunctionComponent<IconProps> = ({
  label,
  name,
  size = 'medium',
  testId = 'icon',
  ...rest
}): JSX.Element => {
  const props = {
    label: label || name,
    size,
    testId: testId || `icon_${name}`,
    ...rest,
  }

  switch (name) {
    case 'activity':
      return <ActivityIcon {...props} />
    case 'add-circle':
      return <AddCircleIcon {...props} />
    case 'add':
      return <AddIcon {...props} />
    case 'add-item':
      return <AddItemIcon {...props} />
    case 'addon':
      return <AddonIcon {...props} />
    case 'app-access':
      return <AppAccessIcon {...props} />
    case 'app-switcher':
      return <AppSwitcherIcon {...props} />
    case 'archive':
      return <ArchiveIcon {...props} />
    case 'arrow-down-circle':
      return <ArrowDownCircleIcon {...props} />
    case 'arrow-down':
      return <ArrowDownIcon {...props} />
    case 'arrow-left-circle':
      return <ArrowLeftCircleIcon {...props} />
    case 'arrow-left':
      return <ArrowLeftIcon {...props} />
    case 'arrow-right-circle':
      return <ArrowRightCircleIcon {...props} />
    case 'arrow-right':
      return <ArrowRightIcon {...props} />
    case 'arrow-up-circle':
      return <ArrowUpCircleIcon {...props} />
    case 'arrow-up':
      return <ArrowUpIcon {...props} />
    case 'attachment':
      return <AttachmentIcon {...props} />
    case 'audio-circle':
      return <AudioCircleIcon {...props} />
    case 'audio':
      return <AudioIcon {...props} />
    case 'backlog':
      return <BacklogIcon {...props} />
    case 'billing-filled':
      return <BillingFilledIcon {...props} />
    case 'billing':
      return <BillingIcon {...props} />
    case 'board':
      return <BoardIcon {...props} />
    case 'book':
      return <BookIcon {...props} />
    case 'bullet-list':
      return <BulletListIcon {...props} />
    case 'calendar-filled':
      return <CalendarFilledIcon {...props} />
    case 'calendar':
      return <CalendarIcon {...props} />
    case 'camera-filled':
      return <CameraFilledIcon {...props} />
    case 'camera-rotate':
      return <CameraRotateIcon {...props} />
    case 'camera-take-picture':
      return <CameraTakePictureIcon {...props} />
    case 'camera':
      return <CameraIcon {...props} />
    case 'canvas':
      return <CanvasIcon {...props} />
    case 'check-circle-outline':
      return <CheckCircleOutlineIcon {...props} />
    case 'check-circle':
      return <CheckCircleIcon {...props} />
    case 'check':
      return <CheckboxIcon {...props} />
    case 'checkbox-indeterminate':
      return <CheckboxIndeterminateIcon {...props} />
    case 'checkbox':
      return <CheckIcon {...props} />
    case 'chevron-down-circle':
      return <ChevronDownCircleIcon {...props} />
    case 'chevron-down':
      return <ChevronDownIcon {...props} />
    case 'chevron-left-circle':
      return <ChevronLeftCircleIcon {...props} />
    case 'chevron-left-large':
      return <ChevronLeftLargeIcon {...props} />
    case 'chevron-left':
      return <ChevronLeftIcon {...props} />
    case 'chevron-right-circle':
      return <ChevronRightCircleIcon {...props} />
    case 'chevron-right-large':
      return <ChevronRightLargeIcon {...props} />
    case 'chevron-right':
      return <ChevronRightIcon {...props} />
    case 'chevron-up-circle':
      return <ChevronUpCircleIcon {...props} />
    case 'chevron-up':
      return <ChevronUpIcon {...props} />
    case 'child-issues':
      return <ChildIssuesIcon {...props} />
    case 'code':
      return <CodeIcon {...props} />
    case 'comment':
      return <CommentIcon {...props} />
    case 'component':
      return <ComponentIcon {...props} />
    case 'copy':
      return <CopyIcon {...props} />
    case 'creditcard-filled':
      return <CreditcardFilledIcon {...props} />
    case 'creditcard':
      return <CreditcardIcon {...props} />
    case 'cross-circle':
      return <CrossCircleIcon {...props} />
    case 'cross':
      return <CrossIcon {...props} />
    case 'dashboard':
      return <DashboardIcon {...props} />
    case 'decision':
      return <DecisionIcon {...props} />
    case 'department':
      return <DepartmentIcon {...props} />
    case 'detail-view':
      return <DetailViewIcon {...props} />
    case 'discover-filled':
      return <DiscoverFilledIcon {...props} />
    case 'discover':
      return <DiscoverIcon {...props} />
    case 'document-filled':
      return <DocumentFilledIcon {...props} />
    case 'document':
      return <DocumentIcon {...props} />
    case 'documents':
      return <DocumentsIcon {...props} />
    case 'download':
      return <DownloadIcon {...props} />
    case 'drag-handler':
      return <DragHandlerIcon {...props} />
    case 'dropbox':
      return <DropboxIcon {...props} />
    case 'edit-filled':
      return <EditFilledIcon {...props} />
    case 'edit':
      return <EditIcon {...props} />
    case 'email':
      return <EmailIcon {...props} />
    case 'emoji-add':
      return <EmojiAddIcon {...props} />
    case 'emoji':
      return <EmojiIcon {...props} />
    case 'error':
      return <ErrorIcon {...props} />
    case 'export':
      return <ExportIcon {...props} />
    case 'feedback':
      return <FeedbackIcon {...props} />
    case 'file':
      return <FileIcon {...props} />
    case 'filter':
      return <FilterIcon {...props} />
    case 'flag-filled':
      return <FlagFilledIcon {...props} />
    case 'folder-filled':
      return <FolderFilledIcon {...props} />
    case 'folder':
      return <FolderIcon {...props} />
    case 'followers':
      return <FollowersIcon {...props} />
    case 'following':
      return <FollowingIcon {...props} />
    case 'googledrive':
      return <GoogledriveIcon {...props} />
    case 'graph-bar':
      return <GraphBarIcon {...props} />
    case 'graph-line':
      return <GraphLineIcon {...props} />
    case 'gsuite':
      return <GsuiteIcon {...props} />
    case 'highlights':
      return <HighlightsIcon {...props} />
    case 'home-circle':
      return <HomeCircleIcon {...props} />
    case 'home':
      return <HomeIcon {...props} />
    case 'image-border':
      return <ImageBorderIcon {...props} />
    case 'image-resize':
      return <ImageResizeIcon {...props} />
    case 'image':
      return <ImageIcon {...props} />
    case 'info':
      return <InfoIcon {...props} />
    case 'invite-team':
      return <InviteTeamIcon {...props} />
    case 'issue-raise':
      return <IssueRaiseIcon {...props} />
    case 'issue':
      return <IssueIcon {...props} />
    case 'issues':
      return <IssuesIcon {...props} />
    case 'label':
      return <LabelIcon {...props} />
    case 'lightbulb-filled':
      return <LightbulbFilledIcon {...props} />
    case 'lightbulb':
      return <LightbulbIcon {...props} />
    case 'like':
      return <LikeIcon {...props} />
    case 'link-filled':
      return <LinkFilledIcon {...props} />
    case 'link':
      return <LinkIcon {...props} />
    case 'list':
      return <ListIcon {...props} />
    case 'location':
      return <LocationIcon {...props} />
    case 'lock-circle':
      return <LockCircleIcon {...props} />
    case 'lock-filled':
      return <LockFilledIcon {...props} />
    case 'lock':
      return <LockIcon {...props} />
    case 'marketplace':
      return <MarketplaceIcon {...props} />
    case 'mention':
      return <MentionIcon {...props} />
    case 'menu-expand':
      return <MenuExpandIcon {...props} />
    case 'menu':
      return <MenuIcon {...props} />
    case 'mobile':
      return <MobileIcon {...props} />
    case 'more-vertical':
      return <MoreVerticalIcon {...props} />
    case 'more':
      return <MoreIcon {...props} />
    case 'notification-all':
      return <NotificationAllIcon {...props} />
    case 'notification-direct':
      return <NotificationDirectIcon {...props} />
    case 'notification':
      return <NotificationIcon {...props} />
    case 'office-building-filled':
      return <OfficeBuildingFilledIcon {...props} />
    case 'office-building':
      return <OfficeBuildingIcon {...props} />
    case 'open':
      return <OpenIcon {...props} />
    case 'overview':
      return <OverviewIcon {...props} />
    case 'page-filled':
      return <PageFilledIcon {...props} />
    case 'page':
      return <PageIcon {...props} />
    case 'pdf':
      return <PdfIcon {...props} />
    case 'people-group':
      return <PeopleGroupIcon {...props} />
    case 'people':
      return <PeopleIcon {...props} />
    case 'person-circle':
      return <PersonCircleIcon {...props} />
    case 'person-with-circle':
      return <PersonWithCircleIcon {...props} />
    case 'person-with-cross':
      return <PersonWithCrossIcon {...props} />
    case 'person-with-tick':
      return <PersonWithTickIcon {...props} />
    case 'person':
      return <PersonIcon {...props} />
    case 'portfolio':
      return <PortfolioIcon {...props} />
    case 'preferences':
      return <PreferencesIcon {...props} />
    case 'premium':
      return <PremiumIcon {...props} />
    case 'presence-active':
      return <PresenceActiveIcon {...props} />
    case 'presence-busy':
      return <PresenceBusyIcon {...props} />
    case 'presence-unavailable':
      return <PresenceUnavailableIcon {...props} />
    case 'question-circle':
      return <QuestionCircleIcon {...props} />
    case 'question':
      return <QuestionIcon {...props} />
    case 'questions':
      return <QuestionsIcon {...props} />
    case 'queues':
      return <QueuesIcon {...props} />
    case 'quote':
      return <QuoteIcon {...props} />
    case 'radio':
      return <RadioIcon {...props} />
    case 'recent':
      return <RecentIcon {...props} />
    case 'redo':
      return <RedoIcon {...props} />
    case 'refresh':
      return <RefreshIcon {...props} />
    case 'retry':
      return <RetryIcon {...props} />
    case 'roadmap':
      return <RoadmapIcon {...props} />
    case 'room-menu':
      return <RoomMenuIcon {...props} />
    case 'schedule-filled':
      return <ScheduleFilledIcon {...props} />
    case 'schedule':
      return <ScheduleIcon {...props} />
    case 'screen':
      return <ScreenIcon {...props} />
    case 'search':
      return <SearchIcon {...props} />
    case 'select-clear':
      return <SelectClearIcon {...props} />
    case 'send':
      return <SendIcon {...props} />
    case 'settings':
      return <SettingsIcon {...props} />
    case 'share':
      return <ShareIcon {...props} />
    case 'ship':
      return <ShipIcon {...props} />
    case 'shortcut':
      return <ShortcutIcon {...props} />
    case 'sign-in':
      return <SignOutIcon {...props} />
    case 'sign-out':
      return <SignInIcon {...props} />
    case 'sprint':
      return <SprintIcon {...props} />
    case 'star-filled':
      return <StarFilledIcon {...props} />
    case 'star-large':
      return <StarLargeIcon {...props} />
    case 'star':
      return <StarIcon {...props} />
    case 'status':
      return <StatusIcon {...props} />
    case 'stopwatch':
      return <StopwatchIcon {...props} />
    case 'subtask':
      return <SubtaskIcon {...props} />
    case 'suitcase':
      return <SuitcaseIcon {...props} />
    case 'switcher':
      return <SwitcherIcon {...props} />
    case 'table':
      return <TableIcon {...props} />
    case 'task':
      return <TaskIcon {...props} />
    case 'trash':
      return <TrashIcon {...props} />
    case 'tray':
      return <TrayIcon {...props} />
    case 'undo':
      return <UndoIcon {...props} />
    case 'unlink':
      return <UnlinkIcon {...props} />
    case 'unlock-circle':
      return <UnlockCircleIcon {...props} />
    case 'unlock-filled':
      return <UnlockFilledIcon {...props} />
    case 'unlock':
      return <UnlockIcon {...props} />
    case 'upload':
      return <UploadIcon {...props} />
    case 'user-avatar-circle':
      return <UserAvatarCircleIcon {...props} />
    case 'vid-audio-muted':
      return <VidAudioMutedIcon {...props} />
    case 'vid-audio-on':
      return <VidAudioOnIcon {...props} />
    case 'vid-backward':
      return <VidBackwardIcon {...props} />
    case 'vid-camera-off':
      return <VidCameraOffIcon {...props} />
    case 'vid-camera-on':
      return <VidCameraOnIcon {...props} />
    case 'vid-connection-circle':
      return <VidConnectionCircleIcon {...props} />
    case 'vid-forward':
      return <VidForwardIcon {...props} />
    case 'vid-full-screen-off':
      return <VidFullScreenOffIcon {...props} />
    case 'vid-full-screen-on':
      return <VidFullScreenOnIcon {...props} />
    case 'vid-hang-up':
      return <VidHangUpIcon {...props} />
    case 'vid-hd-circle':
      return <VidHdCircleIcon {...props} />
    case 'vid-pause':
      return <VidPauseIcon {...props} />
    case 'vid-play':
      return <VidPlayIcon {...props} />
    case 'vid-raised-hand':
      return <VidRaisedHandIcon {...props} />
    case 'vid-share-screen':
      return <VidShareScreenIcon {...props} />
    case 'vid-speaking-circle':
      return <VidSpeakingCircleIcon {...props} />
    case 'vid-volume-full':
      return <VidVolumeFullIcon {...props} />
    case 'vid-volume-half':
      return <VidVolumeHalfIcon {...props} />
    case 'vid-volume-muted':
      return <VidVolumeMutedIcon {...props} />
    case 'video-circle':
      return <VideoCircleIcon {...props} />
    case 'video-filled':
      return <VideoFilledIcon {...props} />
    case 'warning':
      return <WarningIcon {...props} />
    case 'watch-filled':
      return <WatchFilledIcon {...props} />
    case 'watch':
      return <WatchIcon {...props} />
    case 'world':
      return <WorldIcon {...props} />
    case 'builds':
      return <BitbucketBuildsIcon {...props} />
    default:
      return <WorldIcon {...props} />
  }
}

export default Icon
