import React from 'react'

import EmptyState from 'packages/empty-state'
import EmptyLayout from 'layouts/empty'

import NoInternetConnectionImage from 'assets/images/no-internet-connection.png'

const NoInternetConnection: React.FunctionComponent = () => {
  return (
    <EmptyLayout>
      <EmptyState
        title="No internet connection."
        description={
          <React.Fragment>
            You do not currently have an internet connection. Please fix your
            connection to continue using the app.
            <br />
            <br />
            <small>ERR_INTERNET_DISCONNECTED</small>
          </React.Fragment>
        }
        image={{
          src: NoInternetConnectionImage,
        }}
      />
    </EmptyLayout>
  )
}

export default NoInternetConnection
