import React from 'react'

import { checkPermission } from 'core/functions/functions'

const dynamic = (module: any) => React.lazy(() => module)

const Home = dynamic(import('./home'))

const RoleList = dynamic(import('./role/RoleList'))
const UpdateRole = dynamic(import('./role/UpdateRole'))

const WorkflowList = dynamic(import('./workflow/WorkflowList'))
const CreateWorkflow = dynamic(import('./workflow/CreateWorkflow'))
const UpdateWorkflow = dynamic(import('./workflow/UpdateWorkflow'))

const OrgChartList = dynamic(import('./orgChart/OrgChartList'))
const CreateOrgChart = dynamic(import('./orgChart/CreateOrgChart'))
const UpdateOrgChart = dynamic(import('./orgChart/UpdateOrgChart'))

const ProjectsList = dynamic(import('./projects/ProjectsList'))
const CreateProjects = dynamic(import('./projects/CreateProjects'))
const UpdateProjects = dynamic(import('./projects/UpdateProjects'))
const ProjectDetailPage = dynamic(import('./projects/ProjectDetailPage'))
const ProjectLimits = dynamic(import('./projects/ProjectLimits'))

const DemandList = dynamic(import('./demand/DemandList'))
const CreateDemand = dynamic(import('./demand/CreateDemand'))

const WaitApproveDemandList = dynamic(import('./demand/WaitApproveDemandList'))
const ApprovedDemandList = dynamic(import('./demand/ApprovedDemandList'))
const DemandDetail = dynamic(import('./demand/DemandDetail'))
const BuyerDemandDetail = dynamic(import('./demand/BuyerDemandDetail'))

const DegreeList = dynamic(import('./degree/DegreeList'))
const CreateDegree = dynamic(import('./degree/CreateDegree'))
const UpdateDegree = dynamic(import('./degree/UpdateDegree'))

const CategoryList = dynamic(import('./category/CategoryList'))
const CreateCategory = dynamic(import('./category/CreateCategory'))
const UpdateCategory = dynamic(import('./category/UpdateCategory'))

const OfferList = dynamic(import('./offer/OfferList'))
const BuyerOfferList = dynamic(import('./offer/BuyerOfferList'))
const BuyerOfferDetail = dynamic(import('./offer/BuyerOfferDetail'))
const OfferDetail = dynamic(import('./offer/OfferDetail'))

const UnauthorizedPage = dynamic(import('./unauthorized-page'))

const PurchasesList = dynamic(import('./purchases/PurchasesList'))
const CreatePurchases = dynamic(import('./purchases/CreatePurchases'))
const UpdatePurchases = dynamic(import('./purchases/UpdatePurchases'))

const routes: any[] = [
  {
    path: ['/', '/home'],
    component: Home,
  },
  {
    path: '/role',
    component: checkPermission('budget.role.read')
      ? RoleList
      : UnauthorizedPage,
  },
  {
    path: '/role/update/:id',
    component: checkPermission('budget.role.update')
      ? UpdateRole
      : UnauthorizedPage,
  },
  {
    path: '/workflow',
    component: checkPermission('budget.workflowschema.read')
      ? WorkflowList
      : UnauthorizedPage,
  },
  {
    path: '/workflow/create',
    component:
      checkPermission('budget.workflowschema.create') ||
      checkPermission('budget.workflowschema.update')
        ? CreateWorkflow
        : UnauthorizedPage,
  },
  {
    path: '/workflow/update/:id',
    component: checkPermission('budget.workflowschema.read')
      ? UpdateWorkflow
      : UnauthorizedPage,
  },
  {
    path: '/orgChart',
    component:
      checkPermission('budget.organizationchart.create') ||
      checkPermission('budget.organizationchart.update')
        ? OrgChartList
        : UnauthorizedPage,
  },
  {
    path: '/orgChart/create',
    component: checkPermission('budget.organizationchart.create')
      ? CreateOrgChart
      : UnauthorizedPage,
  },
  {
    path: '/orgChart/update/:id',
    component: checkPermission('budget.organizationchart.read')
      ? UpdateOrgChart
      : UnauthorizedPage,
  },
  {
    path: '/projects',
    component:
      checkPermission('budget.project.create') ||
      checkPermission('budget.project.update')
        ? ProjectsList
        : UnauthorizedPage,
  },
  {
    path: '/projects/create',
    component: checkPermission('budget.project.create')
      ? CreateProjects
      : UnauthorizedPage,
  },
  {
    path: '/projects/update/:id',
    component: checkPermission('budget.project.read')
      ? UpdateProjects
      : UnauthorizedPage,
  },
  {
    path: '/projects/detail/:id',
    component: checkPermission('budget.project.detail')
      ? ProjectDetailPage
      : UnauthorizedPage,
  },
  {
    path: '/projects/projectLimits',
    component: true ? ProjectLimits : UnauthorizedPage,
  },
  {
    path: '/demand',
    component: checkPermission('budget.demand.read')
      ? DemandList
      : UnauthorizedPage,
  },
  {
    path: '/demand/create',
    component: checkPermission('budget.demand.create')
      ? CreateDemand
      : UnauthorizedPage,
  },
  {
    path: '/waitApproveDemandList',
    component: checkPermission('budget.demand.read')
      ? WaitApproveDemandList
      : UnauthorizedPage,
  },
  {
    path: '/approvedDemandList',
    component: checkPermission('budget.demand.read')
      ? ApprovedDemandList
      : UnauthorizedPage,
  },
  {
    path: '/demand/demandDetail/:id',
    component: checkPermission('budget.demand.read')
      ? DemandDetail
      : UnauthorizedPage,
  },
  {
    path: '/waitApproveDemand/demandDetail/:id',
    component: checkPermission('budget.demand.read')
      ? DemandDetail
      : UnauthorizedPage,
  },
  {
    path: '/demand/buyerDemandDetail/:id',
    component: checkPermission('budget.demand.read')
      ? BuyerDemandDetail
      : UnauthorizedPage,
  },
  {
    path: '/degree',
    component:
      checkPermission('budget.degree.create') ||
      checkPermission('budget.degree.update')
        ? DegreeList
        : UnauthorizedPage,
  },
  {
    path: '/degree/create',
    component: checkPermission('budget.degree.create')
      ? CreateDegree
      : UnauthorizedPage,
  },
  {
    path: '/degree/update/:id',
    component: checkPermission('budget.degree.read')
      ? UpdateDegree
      : UnauthorizedPage,
  },
  {
    path: '/category',
    component:
      checkPermission('budget.productcategory.create') ||
      checkPermission('budget.productcategory.update')
        ? CategoryList
        : UnauthorizedPage,
  },
  {
    path: '/category/create',
    component: checkPermission('budget.productcategory.create')
      ? CreateCategory
      : UnauthorizedPage,
  },
  {
    path: '/category/update/:id',
    component: checkPermission('budget.productcategory.read')
      ? UpdateCategory
      : UnauthorizedPage,
  },
  {
    path: '/purchases',
    component: checkPermission('budget.purchases.read')
      ? PurchasesList
      : UnauthorizedPage,
  },
  {
    path: '/purchases/create',
    component: checkPermission('budget.purchases.create')
      ? CreatePurchases
      : UnauthorizedPage,
  },
  {
    path: '/purchases/update/:id',
    component: checkPermission('budget.purchases.read')
      ? UpdatePurchases
      : UnauthorizedPage,
  },
  {
    path: '/offer',
    component: checkPermission('budget.contentapproval.read')
      ? OfferList
      : UnauthorizedPage,
  },
  {
    path: '/buyerOffer',
    component: checkPermission('budget.contentapproval.approved')
      ? BuyerOfferList
      : UnauthorizedPage,
  },
  {
    path: '/offer/offerDetail/:id',
    component: checkPermission('budget.offer.read')
      ? OfferDetail
      : UnauthorizedPage,
  },
  {
    path: '/offer/buyerOfferDetail/:id',
    component: checkPermission('budget.offer.read')
      ? BuyerOfferDetail
      : UnauthorizedPage,
  },
]

export default routes
