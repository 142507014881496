import { css } from '@emotion/css'

export const flex = css({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  zIndex: '100',
})

export const flexContent = css({
  width: '100%',
  flex: '1',
  padding: '0 24px',
  boxSizing: 'border-box',
})

export const flexFooter = css({
  position: 'relative',
  width: '100%',
  padding: '24px 0px',
  textAlign: 'center',
  boxSizing: 'border-box',
})
