import { getAccessTokenFromSession } from 'core/functions/functions'
import { StorageName } from 'constants/enums'
import { Http, HttpConfig } from 'packages/classes'
import { storage } from 'packages/helpers'
import { refresh } from './interceptors'

const http = new Http({
  origin: process.env.REACT_APP_BUDGET_API,
  interceptors: {
    refresh,
  },
})

export function request<T>(config: HttpConfig, global?: boolean) {
  config.headers = {
    Authorization: 'Bearer ' + getAccessTokenFromSession(),
    ...config.headers,
  }

  if (!config.headers['Accept-Language']) {
    config.headers['Accept-Language'] = storage.get(StorageName.Language)
  }

  if (!global) {
    config.url = `${storage.get(StorageName.Site)}/${config.url}`
  }

  return http.request<T>(config)
}

export default http
