import React, { useEffect, useState } from 'react'

import Flagcard from 'components/flagcard'
import NoInternetConnection from 'components/no-internet-connection'
import Popup from 'components/popup'

const App: React.FunctionComponent = ({ children }) => {
  const [onLine, setOnLine] = useState<boolean>(window.navigator.onLine)

  const handleOffline = () => {
    setOnLine(false)
  }

  const handleOnline = () => {
    setOnLine(true)
  }

  useEffect(() => {
    window.addEventListener('offline', handleOffline)
    window.addEventListener('online', handleOnline)

    return function cleanup() {
      window.removeEventListener('offline', handleOffline)
      window.removeEventListener('online', handleOnline)
    }
  }, [])

  if (onLine) {
    return (
      <>
        <Flagcard />
        <Popup />
        {children}
      </>
    )
  }

  return <NoInternetConnection />
}

export default App
