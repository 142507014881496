import { RefObject, useEffect, useRef } from 'react'

function useEventListener<T extends HTMLElement = HTMLDivElement>(
  type: keyof HTMLElementEventMap,
  listener: (event: Event) => void,
  elem?: RefObject<T>
) {
  const handler = useRef<(event: Event) => void>()

  useEffect(() => {
    const target = elem ? elem.current : document

    if (!(target && target.addEventListener)) {
      return
    }

    if (handler.current !== listener) {
      handler.current = listener
    }

    const eventListener = (event: Event) => {
      if (!!handler?.current) {
        handler.current(event)
      }
    }

    target.addEventListener(type, eventListener)

    return function cleanup() {
      target.removeEventListener(type, eventListener)
    }
  }, [type, listener, elem])
}

export default useEventListener
