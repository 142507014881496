import AtlaskitSectionMessage from '@atlaskit/section-message'

import { AlertProps } from './types'

const Alert: React.FunctionComponent<AlertProps> = ({
  children,
  ...rest
}): JSX.Element => {
  return <AtlaskitSectionMessage {...rest}>{children}</AtlaskitSectionMessage>
}

export default Alert
