import { request } from 'services/http/instance'

export const getById = (id: any, offerId?: string) =>
  request<any>(
    {
      method: 'get',
      url: `files/${id}${offerId ? '?offerId=' + offerId : ''}`,
      responseType: 'blob',
    },
    true
  )

export const create = (data: FormData) =>
  request<any>(
    {
      method: 'post',
      url: 'files',
      data,
    },
    true
  )
