import { request } from 'services/http-ik/instance'

export const get = (params?: any) =>
  request<any>(
    {
      method: 'get',
      url: 'user',
      params,
    },
    true
  )

export const getById = (id: string) =>
  request<any>(
    {
      method: 'get',
      url: `user/${id}`,
    },
    true
  )

export const getCurrentUser = () =>
  request<any>(
    {
      method: 'get',
      url: `user/GetCurrentUser`,
    },
    true
  )
