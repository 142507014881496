import { LocalStorageItems } from 'constants/enums'

const useIam = () => {
  // const logOut = () => {
  //   localStorage.removeItem(LocalStorageItems.iamCode)
  //   localStorage.removeItem(LocalStorageItems.iamState)
  //   localStorage.removeItem(
  //     process.env.REACT_APP_BUDGET_IDENTITY_SESSION_NAME || ''
  //   )
  //   window.location.href =
  //     process.env.REACT_APP_BUDGET_IDENTITY_AUTHORITY_DOMAIN || '/'
  // }
  const signout = (cb: () => void) => {
    localStorage.removeItem(
      process.env.REACT_APP_BUDGET_IDENTITY_SESSION_NAME as string
    )
    localStorage.removeItem(LocalStorageItems.currentUser)
    localStorage.removeItem(LocalStorageItems.profile_info)
    localStorage.removeItem(LocalStorageItems.permissions)
    sessionStorage.removeItem(
      process.env.REACT_APP_BUDGET_IDENTITY_SESSION_NAME as string
    )
    cb()
  }
  const getUserInfo = (infoName: string) => {
    const info = JSON.parse(
      localStorage.getItem(LocalStorageItems.profile_info) || '{}'
    )
    return info[infoName] || ''
  }

  return {
    signout,
    getUserInfo,
  }
}

export default useIam
