import { FlagcardProvider } from 'core/contexts/flagcard'
import { PopupProvider } from 'core/contexts/popup'
import { LinkProvider } from 'packages/link'
import CustomLink from 'components/custom-link'

const Provider: React.FunctionComponent = ({ children }) => {
  return (
    <LinkProvider component={CustomLink}>
      <FlagcardProvider>
        <PopupProvider>{children}</PopupProvider>
      </FlagcardProvider>
    </LinkProvider>
  )
}

export default Provider
