import { usePopup } from 'core/contexts/popup'

import PopupItem from './PopupItem'

const Popup: React.FunctionComponent = () => {
  const popup = usePopup()

  if (popup.state.length) {
    return (
      <div>
        {popup.state.map((props) => (
          <PopupItem key={props.id} {...props} />
        ))}
      </div>
    )
  }

  return null
}

export default Popup
