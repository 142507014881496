import { useContext } from 'react'

import { LinkContext } from './context'
import { LinkProps } from './types'

const Link: React.FunctionComponent<LinkProps> = ({
  children,
  href,
  testId,
  ...rest
}): JSX.Element => {
  const context = useContext(LinkContext)
  const LinkComponent = context.component

  if (LinkComponent) {
    return (
      <LinkComponent href={href} testId={testId} {...rest}>
        {children}
      </LinkComponent>
    )
  }

  return (
    <a href={href} data-testid={testId} {...rest}>
      {children}
    </a>
  )
}

export default Link
