import React, { useEffect, useState } from 'react'

import type { Error } from 'constants/types'
import { LocalStorageItems, NodeEnv } from 'constants/enums'
import SplashScreen from 'components/splash-screen'
import { useAuth, hasAuthParams } from 'react-oidc-context'
import idService from 'services/id/userinfo'
import { UserInfoResponse } from 'services/id/userinfo/types'
import { getAccessTokenFromSession } from 'core/functions/functions'

const Capsule: React.FunctionComponent = ({ children }) => {
  const [error] = useState<Error>()
  const [isReady] = useState<boolean>(true)
  const auth = useAuth()

  React.useEffect(() => {
    if (
      !hasAuthParams() &&
      !auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading &&
      !getAccessTokenFromSession()
    ) {
      auth.signinRedirect()
    }
  }, [
    auth,
    auth.isAuthenticated,
    auth.activeNavigator,
    auth.isLoading,
    auth.signinRedirect,
    auth.user,
  ])

  useEffect(() => {
    if (process.env.NODE_ENV === NodeEnv.Development) {
      const error: Error = {
        code: 503,
        name: 'Service Unavailable',
        message:
          'The server cannot be reached. There could be many reasons for this.',
      }

      console.log(error)
    }
  }, [])

  if (isReady) {
    return (
      <React.Suspense fallback={<SplashScreen />}>{children}</React.Suspense>
    )
  }

  return <SplashScreen error={error} />
}

export default Capsule
