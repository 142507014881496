import { BaseIcon } from 'packages/logo'

import * as styles from './styles'

const EmptyLayout: React.FunctionComponent = ({ children }) => {
  return (
    <div className={styles.flex}>
      <div className={styles.flexContent}>{children}</div>
      <div className={styles.flexFooter}>
        <BaseIcon />
      </div>
    </div>
  )
}

export default EmptyLayout
