import { request } from 'services/http/instance'

export const authorizedPermissions = () =>
  request<any>(
    {
      method: 'get',
      url: `users/authorized-permissions`,
    },
    true
  )

export const budgetInfo = () =>
  request<any>(
    {
      method: 'get',
      url: `users/info-budget`,
    },
    true
  )
