import React from 'react'
import AtlaskitSpinner from '@atlaskit/spinner'

import { SpinnerProps } from './types'

const Spinner: React.FunctionComponent<SpinnerProps> = ({
  size = 'medium',
  testId = 'spinner',
  ...rest
}): JSX.Element => {
  return <AtlaskitSpinner size={size} testId={testId} {...rest} />
}

export default Spinner
