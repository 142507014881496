import React from 'react'
import { Helmet } from 'react-helmet'
import { colors } from '@atlaskit/theme'

import Spinner from 'packages/spinner'

import type { SplashScreenProps } from './types'
import * as styles from './styles'

const SplashScreen: React.FunctionComponent<SplashScreenProps> = ({
  error,
}) => {
  return (
    <React.Fragment>
      <Helmet>
        <meta
          name="apple-mobile-web-app-status-bar-style"
          content="black-translucent"
        />
        <meta name="theme-color" content={colors.N20A} />
        <style>{`
          body {
            background-color: ${colors.N20A};
          }
        `}</style>
      </Helmet>
      <div className={styles.splashScreenFlex}>
        <div className={styles.splashScreenFlexRow}>
          <div className={styles.splashScreenWrapper}>
            <img
              style={{ maxHeight: 32 }}
              src="/static/images/butcelogoV2.svg"
              alt="ASG Bütçe"
            />
            {error ? (
              <React.Fragment>
                <p>{error.message}</p>
                <p>
                  {error.code}: {error.name}
                </p>
              </React.Fragment>
            ) : (
              <div className={styles.splashScreenProgressBar}>
                <Spinner />
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default SplashScreen
