import ReactDOM from 'react-dom'

import 'packages/css-packs/css-packs.scss'

import './assets/styles/root.scss'
import './assets/styles/vendors.scss'
import './assets/styles/reactflow.scss'

import App from './startup/App'
import Capsule from './startup/Capsule'
import Provider from './startup/Provider'
import Router from './startup/Router'
import reportWebVitals from './reportWebVitals'
import { AuthProvider } from 'react-oidc-context'
import { oidcConfig, urlRequiresAuthentication } from 'core/utils/path'
import { LocalStorageItems } from 'constants/enums'

!urlRequiresAuthentication() &&
  localStorage.setItem(LocalStorageItems.lastPageUrl, window.location.href)

ReactDOM.render(
  <AuthProvider {...oidcConfig}>
    <Provider>
      <App>
        {urlRequiresAuthentication() ? (
          <Router authenticate={false} />
        ) : (
          <Capsule>
            <Router authenticate={true} />
          </Capsule>
        )}
      </App>
    </Provider>
  </AuthProvider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
