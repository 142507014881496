import React from 'react'
import AtlaskitButton, { LoadingButton } from '@atlaskit/button'

import { ButtonProps } from './types'
import { getAppearance, getSize } from '../utils'

const Button: React.ForwardRefRenderFunction<HTMLButtonElement, ButtonProps> = (
  {
    appearance = 'button',
    children,
    isDisabled = false,
    isFullWidth = false,
    isLoading = false,
    onBlur,
    onClick,
    onFocus,
    onSubmit,
    size = 'medium',
    testId,
    ...rest
  },
  ref
): JSX.Element => {
  const handleBlur = (event: React.FocusEvent<HTMLElement>) => {
    if (onBlur) {
      onBlur(event as React.FocusEvent<HTMLButtonElement>)
    }
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (onClick) {
      onClick(event as React.MouseEvent<HTMLButtonElement>)
    }
  }

  const handleFocus = (event: React.FocusEvent<HTMLElement>) => {
    if (onFocus) {
      onFocus(event as React.FocusEvent<HTMLButtonElement>)
    }
  }

  const handleSubmit = (event: React.FormEvent<HTMLElement>) => {
    if (onSubmit) {
      onSubmit(event as React.FormEvent<HTMLButtonElement>)
    }
  }

  if (isLoading) {
    return (
      <LoadingButton
        appearance={getAppearance(appearance)}
        isDisabled={isDisabled}
        isLoading={isLoading}
        onBlur={handleBlur}
        onClick={handleClick}
        onFocus={handleFocus}
        onSubmit={handleSubmit}
        ref={ref}
        shouldFitContainer={isFullWidth}
        spacing={getSize(size)}
        testId={testId}
        {...rest}
      >
        {children}
      </LoadingButton>
    )
  }

  return !rest.hidden ? (
    <AtlaskitButton
      appearance={getAppearance(appearance)}
      isDisabled={isDisabled}
      onBlur={handleBlur}
      onClick={handleClick}
      onFocus={handleFocus}
      onSubmit={handleSubmit}
      ref={ref}
      shouldFitContainer={isFullWidth}
      spacing={getSize(size)}
      testId={testId}
      {...rest}
    >
      {children}
    </AtlaskitButton>
  ) : (
    <></>
  )
}

export default React.forwardRef(Button)
