import { uid } from 'packages/utils'

import { LogoProps } from '../types'
import LogoContainer from '../LogoContainer'

export const BaseLogo: React.FunctionComponent<LogoProps> = ({
  iconGradientStart,
  iconGradientStop,
  ...rest
}) => {
  const id = uid()

  return (
    <LogoContainer {...rest}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="169.012"
        height="47.806"
        viewBox="0 0 169.012 47.806"
      >
        <defs>
          <linearGradient id={id} gradientTransform="rotate(90)">
            <stop offset="0%" stopColor={iconGradientStart} />
            <stop offset="100%" stopColor={iconGradientStop} />
          </linearGradient>
        </defs>
        <g transform="translate(-3899 -365)">
          <g transform="translate(3985.772 398.21)">
            <g transform="translate(0)">
              <path
                d="M4276.538,519.817v-9.663h4.472a3.238,3.238,0,0,1,2.284.808,2.714,2.714,0,0,1,.876,2.091,2.664,2.664,0,0,1-.884,2.07,3.26,3.26,0,0,1-2.277.8h-3.023v3.893Zm4.32-8.392h-2.871V514.7h2.871a1.951,1.951,0,0,0,1.346-.449,1.521,1.521,0,0,0,.5-1.194,1.5,1.5,0,0,0-.5-1.187A1.971,1.971,0,0,0,4280.857,511.424Z"
                transform="translate(-4276.538 -510.002)"
                fill="#1a1a1a"
              />
              <path
                d="M4322.241,519.817v-9.663h1.449v8.379h5.563v1.284Z"
                transform="translate(-4311.736 -510.002)"
                fill="#1a1a1a"
              />
              <path
                d="M4362.18,519.817l4-9.663h1.7l3.948,9.663h-1.574l-1.118-2.83h-4.321l-1.132,2.83Zm3.12-4.031h3.368l-1.684-4.237Z"
                transform="translate(-4342.496 -510.002)"
                fill="#1a1a1a"
              />
              <path
                d="M4403.318,511.438v-1.284h8.241v1.284h-3.4v8.379h-1.449v-8.379Z"
                transform="translate(-4374.179 -510.002)"
                fill="#1a1a1a"
              />
              <path
                d="M4450.465,519.817v-9.663h7.136v1.27h-5.687v2.968h3.754v1.242h-3.754v4.182Z"
                transform="translate(-4410.49 -510.002)"
                fill="#1a1a1a"
              />
              <path
                d="M4499.145,514.477a4.982,4.982,0,0,1-1.462,3.541,4.985,4.985,0,0,1-1.6,1.056,5.239,5.239,0,0,1-3.948,0,4.9,4.9,0,0,1-1.595-1.056,5,5,0,0,1-1.062-1.587,5.049,5.049,0,0,1,0-3.906,5.008,5.008,0,0,1,1.063-1.587,4.9,4.9,0,0,1,1.595-1.056,5.232,5.232,0,0,1,3.948,0,4.886,4.886,0,0,1,2.671,2.643A4.906,4.906,0,0,1,4499.145,514.477Zm-1.477,0a3.749,3.749,0,0,0-.276-1.443,3.624,3.624,0,0,0-.752-1.159,3.407,3.407,0,0,0-1.132-.766,3.578,3.578,0,0,0-1.4-.276,3.522,3.522,0,0,0-1.394.276,3.421,3.421,0,0,0-1.125.766,3.64,3.64,0,0,0-.752,1.159,3.909,3.909,0,0,0,0,2.885,3.489,3.489,0,0,0,1.878,1.919,3.443,3.443,0,0,0,1.394.283,3.5,3.5,0,0,0,1.4-.283,3.473,3.473,0,0,0,1.884-1.919A3.744,3.744,0,0,0,4497.668,514.477Z"
                transform="translate(-4440.232 -509.494)"
                fill="#1a1a1a"
              />
              <path
                d="M4545.6,519.817v-9.663h4.389a3.364,3.364,0,0,1,2.326.794,2.82,2.82,0,0,1,.353,3.713,2.963,2.963,0,0,1-1.45,1l2.154,4.155h-1.6l-2-3.961h-2.719v3.961Zm4.265-8.392h-2.816v3.23h2.816a2,2,0,0,0,1.359-.449,1.58,1.58,0,0,0,0-2.34A2.023,2.023,0,0,0,4549.862,511.424Z"
                transform="translate(-4483.757 -510.002)"
                fill="#1a1a1a"
              />
              <path
                d="M4596.136,519.126l-3.6-7.178v7.868h-1.352v-9.663h1.932l3.037,6.17,3.023-6.17h1.932v9.663h-1.366v-7.882Z"
                transform="translate(-4518.864 -510.002)"
                fill="#1a1a1a"
              />
            </g>
          </g>
          <g transform="translate(3985.334 373.638)">
            <path
              d="M4282.6,418.982h-4.289l-3.676-16.4h4.171l2,10.12,2.875-10.12h3.794l2.875,10.12,2-10.12h4.03l-3.676,16.4h-4.289l-2.9-10.448Z"
              transform="translate(-4274.63 -402.583)"
              fill="#1a1a1a"
            />
            <path
              d="M4379.729,415.023l-1.531,3.959h-4.218l6.763-16.4h4.643l6.668,16.4h-4.383l-1.508-3.959-1.2-3.209-1.979-5.248-2.027,5.248Z"
              transform="translate(-4351.146 -402.583)"
              fill="#1a1a1a"
            />
            <path
              d="M4468.1,418.982v-16.4h8.695a6.2,6.2,0,0,1,2.239.4,5.183,5.183,0,0,1,3.37,4.92,5.037,5.037,0,0,1-.425,2.061,4.881,4.881,0,0,1-1.178,1.652,5.612,5.612,0,0,1-1.779,1.089,6.092,6.092,0,0,1-2.227.4h-4.642v5.88Zm8.224-12.955h-4.171v3.748h4.171a2.01,2.01,0,0,0,1.426-.515,1.741,1.741,0,0,0,.554-1.335,1.806,1.806,0,0,0-.554-1.371A1.984,1.984,0,0,0,4476.324,406.027Z"
              transform="translate(-4423.634 -402.583)"
              fill="#1a1a1a"
            />
          </g>
          <g transform="translate(3899 365)">
            <path
              d="M3899.043,379.213a.566.566,0,0,1,0-.429,28.708,28.708,0,0,1,5.014-7.99.566.566,0,0,1,.952.165l8.476,21.374a.529.529,0,0,0,.982,0l10.94-26.986a.566.566,0,0,1,1.048,0l3.764,9.333-16.035,37.77a.565.565,0,0,1-1.04,0Z"
              transform="translate(-3899 -365)"
              fill="#1a1a1a"
            />
          </g>
          <path
            d="M4051.834,399.956l-10.053-11.918h0l-.962-1.17-.343.852c-.006.012-.012.023-.017.035l-8.569,21.323a.529.529,0,0,1-.983-.007l-4.831-12.326a.565.565,0,0,0-1.047-.018l-4.177,9.774a.521.521,0,0,0,0,.407l9.56,22.4a.565.565,0,0,0,1.04,0l11.994-28.219a1.06,1.06,0,0,1,1.958.811l-1.419,3.426.113-.014,5.748-.691A2.926,2.926,0,0,0,4051.834,399.956Z"
            transform="translate(-93.812 -16.841)"
            fill="#1a1a1a"
          />
          <rect
            width="0.299"
            height="34.475"
            transform="translate(3974.14 373.504)"
          />
        </g>
      </svg>
    </LogoContainer>
  )
}
