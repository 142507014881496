import { css } from '@emotion/css'

import { SIZES } from './constants'
import { LogoProps } from './types'

export const root = (props: LogoProps) =>
  css({
    position: 'relative',
    color: props.iconColor,
    fill: props.textColor,
    height: `${SIZES[props.size || 'medium']}px`,
    display: 'inline-block',
    userSelect: 'none',
    verticalAlign: 'middle',

    canvas: {
      display: 'block',
      height: '100%',
      visibility: 'hidden',
    },
    svg: {
      width: '100%',
      height: '100%',
      fill: 'inherit',
    },
    stop: {
      stopColor:
        props.iconGradientStart === 'inherit' &&
        props.iconGradientStop === 'inherit'
          ? 'currentColor'
          : 'inherit',
    },
  })
