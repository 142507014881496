import React from 'react'

import { LinkContextProps } from './types'

export const LinkContext = React.createContext<LinkContextProps>({})

export const LinkProvider: React.FunctionComponent<LinkContextProps> = ({
  children,
  component,
}) => {
  return (
    <LinkContext.Provider
      value={{
        component,
      }}
    >
      {children}
    </LinkContext.Provider>
  )
}

LinkContext.displayName = 'LinkContext'
