/**
 * The key() method returns name of the key with the specified index.
 *
 * @param index A Number representing the index of the key you want to get the name of
 */

export const key = (index: number): string | null => localStorage.key(index)

/**
 * The length property returns the number of items stored in
 * the browsers Storage Object, for this particular domain..
 */

export const length = (): number => localStorage.length

/**
 * The get() method returns value of the specified Local Storage object item.
 *
 * @param key A String specifying the name of the key you want to get the value of
 */

export const get = (key: string): any => {
  const value = localStorage.getItem(key)

  if (value) {
    return JSON.parse(value)
  }
}

/**
 * The set() method sets the value of the specified Local Storage object item.
 *
 * @param key A String specifying the name of the key you want to set the value of
 * @param value A String specifying the value of the key you want to set the value of
 */

export const set = (key: string, value: unknown): void =>
  localStorage.setItem(key, JSON.stringify(value))

/**
 * The remove() method removes the specified Local Storage object item.
 *
 * @param key A String specifying the name of the item you want to remove
 */

export const remove = (key: string): void => localStorage.removeItem(key)

/**
 * The clear() method removes all the Local Storage object item for this domain.
 */

export const clear = (): void => localStorage.clear()
