import React, { useCallback, useContext, useState } from 'react'
import { uid } from 'begonya/functions'

import type {
  PopupAddHandler,
  PopupRemoveHandler,
  PopupState,
  PopupValue,
} from './types'

export const PopupContext = React.createContext<PopupValue | null>(null)

export const PopupProvider: React.FunctionComponent = ({ children }) => {
  const [state, setState] = useState<PopupState>([])

  const add: PopupAddHandler = useCallback(({ id, ...rest }) => {
    setState((prevState) => [
      ...prevState,
      {
        id: id || uid(),
        appearance: 'error',
        ...rest,
      },
    ])
  }, [])

  const remove: PopupRemoveHandler = useCallback((id) => {
    setState((prevState) => prevState.filter((data) => data.id !== id))
  }, [])

  return (
    <PopupContext.Provider
      value={{
        state,
        add,
        remove,
      }}
    >
      {children}
    </PopupContext.Provider>
  )
}

export const usePopup = (): PopupValue => {
  const popup = useContext(PopupContext)

  if (popup) {
    return popup
  }

  throw new Error('usePopup must be used within a PopupProvider.')
}
