import React, { useEffect, useState, useRef } from 'react'

import { useFlagcard, FlagcardData } from 'core/contexts/flagcard'
import Button from 'packages/button'
import Alert from 'packages/alert'
import Icon from 'packages/icon'

import * as styles from './styles'

const FlagcardItem: React.FunctionComponent<FlagcardData> = ({
  children,
  id,
  ...rest
}) => {
  const [countdown, setCountdown] = useState(3)
  const timerRef = useRef<NodeJS.Timer>()
  const flagcard = useFlagcard()

  const setTimer = () => {
    timerRef.current = setInterval(() => {
      setCountdown((countdown) => countdown - 1)
    }, 1000)
  }

  const clearTimer = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current)
    }
  }

  useEffect(() => {
    setTimer()

    return function cleanup() {
      clearTimer()
    }
  }, [])

  useEffect(() => {
    if (countdown === 0) {
      clearTimer()

      if (id) {
        flagcard.remove(id)
      }
    }
  }, [flagcard, countdown, id])

  return (
    <div className={styles.flagcardItem}>
      <div
        className={styles.flagcardItemWrapper}
        onMouseEnter={clearTimer}
        onMouseLeave={setTimer}
      >
        <Alert {...rest}>
          <p>{children}</p>
        </Alert>
        <Button
          appearance="subtle"
          iconBefore={<Icon name="cross-circle" />}
          onClick={() => setCountdown(0)}
        />
      </div>
      <div className={styles.flagcardCountdown}>
        {countdown} saniye sonra otomatik kaybolacak
      </div>
    </div>
  )
}

export default FlagcardItem
