import {
  Appearance as AtlaskitAppearance,
  Spacing as AtlaskitSize,
} from '@atlaskit/button'

import { Appearance, Size } from './types'

export const getAppearance = (appearance: Appearance): AtlaskitAppearance => {
  switch (appearance) {
    case 'button':
      return 'default'
    case 'error':
      return 'danger'
    default:
      return appearance
  }
}

export const getSize = (size: Size): AtlaskitSize => {
  switch (size) {
    case 'small':
      return 'compact'
    case 'medium':
      return 'default'
    default:
      return size
  }
}
