// nested array to single array

import { LocalStorageItems } from 'constants/enums'
import fileService from 'services/http/files'
import { uid } from 'packages/utils'

export function getAsDistinct(
  array: Record<string, any>[] = [],
  field: string
): Record<string, any>[] {
  const result = [...array]

  for (let index = 0; index < array.length; index++) {
    if (Array.isArray(array[index][field])) {
      result.push(...getAsDistinct(array[index][field], field))
    } else {
      result.push(array[index])
    }
  }

  return result
}

export const getAccessTokenFromSession = () => {
  return JSON.parse(
    localStorage.getItem(
      process.env.REACT_APP_BUDGET_IDENTITY_SESSION_NAME || ''
    ) || '{}'
  ).access_token
}

export const checkPermission = (permission: string) => {
  //superadminin herşeye yetkisi vardır.
  if (isSuperAdmin()) {
    return true
  }

  let permissions: any = []
  try {
    permissions = JSON.parse(
      localStorage.getItem(LocalStorageItems.permissions) || '{}'
    )
    return !!permissions.permissions.find((item: any) => item === permission)
  } catch (error) {
    return false
  }
}

export const getCurrentUserId = () => {
  return (
    JSON.parse(localStorage.getItem(LocalStorageItems.profile_info) || '{}')
      ?.sub || ''
  )
}

export const currentUser = JSON.parse(
  localStorage.getItem(LocalStorageItems.currentUser) || '{}'
)

export const isSuperAdmin = () => {
  return JSON.parse(localStorage.getItem(LocalStorageItems.permissions) || '{}')
    ?.isSuperAdmin
}

export const transformHyperlinks = (text: string) => {
  const postmsg = text

  const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g
  const detectURL: any = postmsg.match(urlRegex)

  let resultPost = postmsg

  detectURL?.forEach((url: any) => {
    resultPost = resultPost.replace(
      url,
      '<a href= "' +
        url +
        '" role="link" target="_blank" > ' +
        url.trim() +
        '</a>'
    )
  })

  return resultPost
}

export function searchToObject(search: string) {
  var pairs = search.substring(1).split('&'),
    obj: any = {},
    pair,
    i

  for (i in pairs) {
    if (pairs[i] === '') continue

    pair = pairs[i].split('=')
    obj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1])
  }

  return obj
}

export const padL = (nr: any, len = 2, chr = `0`) => `${nr}`.padStart(2, chr)

export const downloadFile = (item: any, flagcard: any, offerId?: string) => {
  fileService
    .getById(item.id, offerId)
    .then((res: any) => {
      const link = document.createElement('a')
      link.style.display = 'none'
      link.download = link.download = item.fileName
      var blob = new Blob([res.data], { type: res.headers['content-type'] })
      link.href = window.URL.createObjectURL(blob)
      link.click()
      flagcard.add({
        id: uid(),
        appearance: 'success',
        title: 'Dosya indirildi',
        children: '',
      })
    })
    .catch(() => {
      flagcard.add({
        id: uid(),
        appearance: 'error',
        title: 'Dosya indirilemedi',
        children:
          'Dosya indirilirken bir hata oluştu. Lütfen servis sağlayıcınız ile iletişime geçiniz.',
      })
    })
}

export const formatter = new Intl.DateTimeFormat('tr-TR', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: 'numeric',
  minute: '2-digit',
  second: '2-digit',
  hourCycle: 'h12',
  timeZone: 'UTC',
})
