import { request } from 'services/id/instance'

import { UserInfo } from './types'

export const get = () =>
  request<UserInfo>(
    {
      method: 'get',
      url: 'userinfo',
    },
    true
  )
