import { useRef } from 'react'
import { useLocation } from 'react-router-dom'

function useSearchParams(queryString?: string) {
  const location = useLocation()
  const instance = new URLSearchParams(
    queryString === undefined ? location.search.slice(1) : queryString
  )
  const reference = useRef(Object.fromEntries(instance))

  return reference.current
}

export default useSearchParams
