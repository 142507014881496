import { useFlagcard } from 'core/contexts/flagcard'

import FlagcardsItem from './FlagcardItem'
import * as styles from './styles'

const Flagcards: React.FunctionComponent = () => {
  const flagcard = useFlagcard()

  if (flagcard.state.length) {
    return (
      <div className={styles.flagcard}>
        {flagcard.state.map((props) => (
          <FlagcardsItem key={props.id} {...props} />
        ))}
      </div>
    )
  }

  return null
}

export default Flagcards
