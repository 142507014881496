import type { NotFoundProps } from './types'

import NotFoundImage from 'assets/images/not-found.png'
import EmptyLayout from 'layouts/empty'

import Button from 'packages/button'
import Icon from 'packages/icon'
import EmptyState from 'packages/empty-state'

const NotFound: React.FunctionComponent<NotFoundProps> = ({
  history,
  match,
}) => {
  const primaryAction = (
    <Button
      iconBefore={<Icon name="arrow-left" size="small" label="Turn back" />}
      onClick={() => history.goBack()}
    >
      Geri dön
    </Button>
  )

  return (
    <EmptyLayout>
      <EmptyState
        title="404. Aradığınız sayfa bulunamadı."
        description={`Aradığınız "${match.url}" sayfası sistem üzerinde bulunamadı.`}
        image={{
          src: NotFoundImage,
        }}
        actions={primaryAction}
      />
    </EmptyLayout>
  )
}

export default NotFound
