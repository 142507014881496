import { WebStorageStateStore } from 'oidc-client-ts'
import { urlNotRequiringAuthenticationList } from 'constants/definitions'

/**
 * The ``app`` function returns the fully qualified path to the app directory.
 * You may also use the ``app`` function to generate a fully qualified path
 * to a given file relative to the application directory.
 */

export const app = (path: string = '') =>
  process.env.REACT_APP_BUDGET_WEB + '/' + path

/**
 * Create a URL for an entity using the current schema of the request.
 * Indicates the static folder.
 */

export const assets = (path: string) => app('static') + path

/**
 * The ``storage`` function returns the full
 * path of the file server directory.
 */

export const storage = (path: string) => process.env.REACT_APP_CDN + '/' + path

/**
 * The ``drive`` function returns the full
 * path of the drive application directory.
 */

export const drive = () => process.env.REACT_APP_BUDGET_DRIVE

/**
 * The ``query`` function returns the full
 * path of the query endpoint.
 */

export const query = (endpoint: string) =>
  process.env.REACT_APP_BUDGET_API + '/query/' + endpoint

/**
 * The ``checkAuthenticateRoute`` function checks
 * if you have said a url that requires authentication
 */

export const urlRequiresAuthentication = () =>
  urlNotRequiringAuthenticationList.includes(
    window.location.pathname.replace('/', '')
  )

/**
 * The ``oidcConfig`` function is OIDC connection configuration object
 */

export const oidcConfig = {
  authority: process.env.REACT_APP_BUDGET_IDENTITY_AUTHORITY_DOMAIN || '',
  client_id: process.env.REACT_APP_BUDGET_IDENTITY_CLIENT_ID || '',
  scope: process.env.REACT_APP_BUDGET_IDENTITY_SCOPE || '',
  response_type: process.env.REACT_APP_BUDGET_IDENTITY_RESPONSE_TYPE || '',
  redirect_uri: process.env.REACT_APP_BUDGET_IDENTITY_LOGIN_RETURN_URL || '',
  automaticSilentRenew: false,
  userStore: new WebStorageStateStore({
    store: localStorage,
  }),
}
