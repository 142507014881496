import React from 'react'

import { LogoProps } from './types'
import * as styles from './styles'

const Container: React.FunctionComponent<LogoProps> = ({
  children,
  ...rest
}) => (
  <div className={styles.root(rest)} role="presentation" {...rest}>
    {children}
  </div>
)

export default Container
