import AtlaskitModal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle as AtlaskitModalTitle,
  ModalTransition as AtlaskitModalTransition,
} from '@atlaskit/modal-dialog'

import Button from 'packages/button'

import { ModalProps, ModalAppearance } from './types'

const getAppearance = (appearance: ModalAppearance) => {
  switch (appearance) {
    case 'error':
      return 'danger'
    default:
      return appearance
  }
}

const Modal: React.FunctionComponent<ModalProps> = ({
  autoFocus = true,
  actions,
  appearance,
  children,
  title,
  isHeadingMultiline,
  height,
  width,
  onClose,
  onCloseComplete,
  onOpenComplete,
  onStackChange,
  scrollBehavior,
  isBlanketHidden,
  stackIndex,
  testId,
  shouldCloseOnOverlayClick = true,
  topActions,
  ...rest
}) => {
  const ModalTitle = () => {
    if (title) {
      return (
        <AtlaskitModalTitle
          {...(appearance && { appearance: getAppearance(appearance) })}
          isMultiline={isHeadingMultiline}
        >
          {title}
        </AtlaskitModalTitle>
      )
    }

    return null
  }

  return (
    <AtlaskitModalTransition>
      <AtlaskitModal
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autoFocus}
        height={height}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        isBlanketHidden={isBlanketHidden}
        onClose={onClose}
        onCloseComplete={onCloseComplete}
        onOpenComplete={onOpenComplete}
        onStackChange={onStackChange}
        shouldScrollInViewport={scrollBehavior !== 'outside'}
        stackIndex={stackIndex}
        testId={testId}
        width={width}
        {...rest}
      >
        <ModalHeader>
          {topActions ? (
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <div style={{ flex: 1 }}>
                <ModalTitle />
              </div>
              <div>{topActions}</div>
            </div>
          ) : (
            <ModalTitle />
          )}
        </ModalHeader>
        <ModalBody>{children}</ModalBody>
        {actions && (
          <ModalFooter>
            {actions.map(({ text, ...props }, index) => (
              <Button
                key={index}
                appearance={index === 0 ? appearance || 'primary' : 'button'}
                {...props}
              >
                {text}
              </Button>
            ))}
          </ModalFooter>
        )}
      </AtlaskitModal>
    </AtlaskitModalTransition>
  )
}

export default Modal
