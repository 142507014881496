import { HttpStatus, LocalStorageItems } from 'constants/enums'
import { HttpConfig } from 'packages/classes'

export const refresh = async (
  config: HttpConfig
): Promise<HttpConfig | void> => {
  const refreshToken = JSON.parse(
    localStorage.getItem(
      process.env.REACT_APP_BUDGET_IDENTITY_SESSION_NAME || ''
    ) || ''
  ).refresh_token

  const onLogout = () => {
    localStorage.removeItem(LocalStorageItems.iamCode)
    localStorage.removeItem(LocalStorageItems.iamState)
    localStorage.removeItem(
      process.env.REACT_APP_BUDGET_IDENTITY_SESSION_NAME || ''
    )
    window.location.href = '/'
  }

  if (!refreshToken || config.url.indexOf('refresh-token') !== -1) {
    onLogout()

    return Promise.resolve(config)
  }

  const req = {
    grant_type: 'refresh_token',
    client_id: 'BUDGET-app',
    refresh_token: refreshToken,
  }
  try {
    const res_ = await fetch(
      `${process.env.REACT_APP_BUDGET_IDENTITY_INFO_API}/token`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams(req),
      }
    )
    if (
      res_.status === HttpStatus.Failure ||
      res_.status === HttpStatus.Unauthorized
    ) {
      onLogout()
    } else {
      try {
        const res = await res_.json()
        const { access_token } = res

        if (access_token) {
          localStorage.setItem(
            process.env.REACT_APP_BUDGET_IDENTITY_SESSION_NAME || '',
            JSON.stringify(res)
          )
          config.headers = {
            ...config.headers,
            Authorization: `Bearer ${access_token}`,
          }

          return config
        }
      } catch (error) {
        onLogout()
      }
    }
  } catch (error) {
    onLogout()
  }
}
