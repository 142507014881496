import { css } from '@emotion/css'

export const flagcard = css({
  position: 'fixed',
  top: 66,
  right: 8,
  maxWidth: '100%',
  maxHeight: 'calc(100% - 72px)',
  width: 425,
  height: 'auto',
  padding: 8,
  zIndex: 999,
  overflowX: 'hidden',
  boxSizing: 'border-box',
})

export const flagcardItem = css({
  width: '100%',
  animation: '350ms slidein cubic-bezier(0.15, 1, 0.3, 1)',

  ':not(:first-child)': {
    marginTop: 8,
  },

  '@keyframes slidein': {
    from: {
      opacity: '0.2',
      marginLeft: '50%',
    },
    to: {
      opacity: '1',
      marginLeft: '0%',
    },
  },
})

export const flagcardItemWrapper = css({
  position: 'relative',
  borderRadius: 4,
  boxShadow: 'rgb(9 30 66 / 30%) 0 0 4px',

  '& > button': {
    position: 'absolute',
    top: 8,
    right: 8,
  },
})

export const flagcardCountdown = css({
  fontSize: 11,
  marginTop: 4,
  textAlign: 'right',
  fontStyle: 'italic',
})
