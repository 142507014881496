import { PopupData, usePopup } from 'core/contexts/popup'
import Modal from 'packages/modal'

const Popup: React.FunctionComponent<PopupData> = ({
  children,
  id,
  ...rest
}) => {
  const popup = usePopup()

  const handleClose = () => {
    if (id) {
      popup.remove(id)
    }
  }

  return (
    <Modal
      actions={[
        {
          text: 'Kapat',
          onClick: handleClose,
        },
      ]}
      width="small"
      onClose={handleClose}
      {...rest}
    >
      {children}
    </Modal>
  )
}

export default Popup
