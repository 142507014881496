import React, { useCallback, useContext, useState } from 'react'
import { uid } from 'begonya/functions'

import type {
  FlagcardAddHandler,
  FlagcardRemoveHandler,
  FlagcardState,
  FlagcardValue,
} from './types'

export const FlagcardContext = React.createContext<FlagcardValue | null>(null)

export const FlagcardProvider: React.FunctionComponent = ({ children }) => {
  const [state, setState] = useState<FlagcardState>([])

  const add: FlagcardAddHandler = useCallback(({ id, ...rest }) => {
    setState((prevState) => [
      ...prevState,
      {
        id: id || uid(),
        appearance: 'success',
        ...rest,
      },
    ])
  }, [])

  const remove: FlagcardRemoveHandler = useCallback((id) => {
    setState((prevState) => prevState.filter((data) => data.id !== id))
  }, [])

  return (
    <FlagcardContext.Provider
      value={{
        state,
        add,
        remove,
      }}
    >
      {children}
    </FlagcardContext.Provider>
  )
}

export const useFlagcard = (): FlagcardValue => {
  const flagcard = useContext(FlagcardContext)

  if (flagcard) {
    return flagcard
  }

  throw new Error('useFlagcard must be used within a FlagcardProvider.')
}
