import AtlaskitEmptyState from '@atlaskit/empty-state'

import { EmptyStateProps } from './types'

const EmptyState: React.FunctionComponent<EmptyStateProps> = ({
  actions,
  description,
  image = {},
  isLoading,
  title,
  ...rest
}): JSX.Element => {
  return (
    <AtlaskitEmptyState
      description={description}
      header={title}
      imageHeight={image.height}
      imageUrl={image.src}
      imageWidth={image.width}
      isLoading={isLoading}
      maxImageHeight={image.maxHeight}
      maxImageWidth={image.maxWidth}
      primaryAction={actions}
      {...rest}
    />
  )
}

export default EmptyState
