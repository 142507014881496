import { BrowserRouter, Route, Switch } from 'react-router-dom'

import type { RouteProps, RouterProps } from 'constants/types'
import SuccessLogin from 'pages/success-login'
import NotFound from 'pages/not-found'
import routes from 'pages/routes'

const pages: RouteProps[] = []

const createRoute = ({ children, ...rest }: RouteProps) => {
  if (rest.component) {
    pages.push(rest)
  }

  if (children) {
    children.forEach((props) =>
      createRoute({ ...props, path: `${rest.path}${props.path}` })
    )
  }
}

routes.forEach((props) => createRoute(props))

function Router({ authenticate }: RouterProps) {
  if (authenticate) {
    return (
      <BrowserRouter>
        <Switch>
          {pages.map(({ component: Page, ...rest }, index) => (
            <Route
              exact
              key={index}
              render={Page ? (props) => <Page {...props} /> : NotFound}
              {...rest}
            />
          ))}
          <Route path="*" component={NotFound} />
        </Switch>
      </BrowserRouter>
    )
  } else {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact component={SuccessLogin} path={'/successLogin'} />
        </Switch>
      </BrowserRouter>
    )
  }
}

export default Router
