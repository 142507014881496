import AtlaskitButton from '@atlaskit/button'

import Link, { LinkProps } from 'packages/link'

import { LinkButtonProps } from './types'
import { getAppearance, getSize } from '../utils'

const LinkButton: React.FunctionComponent<LinkButtonProps> = ({
  appearance = 'link',
  children,
  isFullWidth = false,
  onBlur,
  onClick,
  onSubmit,
  size = 'medium',
  testId,
  ...rest
}): JSX.Element => {
  const handleBlur = (event: React.FocusEvent<HTMLElement>) => {
    if (onBlur) {
      onBlur(event as React.FocusEvent<HTMLAnchorElement>)
    }
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (onClick) {
      onClick(event as React.MouseEvent<HTMLAnchorElement>)
    }
  }

  const handleSubmit = (event: React.FormEvent<HTMLElement>) => {
    if (onSubmit) {
      onSubmit(event as React.FormEvent<HTMLAnchorElement>)
    }
  }

  return !rest.hidden ? (
    <AtlaskitButton
      appearance={getAppearance(appearance)}
      component={(props: LinkProps) => <Link {...props} />}
      onBlur={handleBlur}
      onClick={handleClick}
      onSubmit={handleSubmit}
      shouldFitContainer={isFullWidth}
      spacing={getSize(size)}
      testId={testId}
      {...rest}
    >
      {children}
    </AtlaskitButton>
  ) : (
    <></>
  )
}

export default LinkButton
