import { colors } from '@atlaskit/theme'
import { css } from '@emotion/css'

export const splashScreenFlex = css({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  zIndex: 100,
})

export const splashScreenFlexRow = css({
  flex: 'auto',
})

export const splashScreenWrapper = css({
  color: 'rgba(255, 255, 255, 0.85)',
  maxWidth: '500px',
  width: '100%',
  textAlign: 'center',
  margin: '0 auto',

  svg: {
    width: '60px !important',
    height: '60px !important',
  },
})

export const splashScreenProgressBar = css({
  color: '#fff',
  maxWidth: '250px',
  width: '100%',
  textAlign: 'center',
  margin: '0 auto',
  marginTop: '3.5rem',
  height: '2px',

  svg: {
    stroke: colors.N900,
    width: 'inherit !important',
    height: 'inherit !important',
  },
})
