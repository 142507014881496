import { useEffect, useState } from 'react'

import { useSearchParams } from 'core/hooks'

import idService from 'services/id/userinfo'
import { UserInfoResponse } from 'services/id/userinfo/types'
import userService from 'services/http/users'
import userIkService from 'services/http-ik/user'

import { LocalStorageItems } from 'constants/enums'

import EmptyLayout from 'layouts/empty'

import UnauthorizedPageImage from 'assets/images/unauthorizedPage.png'

import SplashScreen from 'components/splash-screen'

import EmptyState from 'packages/empty-state'
import Button from 'packages/button'
import Icon from 'packages/icon'

const SuccessLogin = () => {
  const params = useSearchParams()

  const [unautorizedApplication, setunautorizedApplication] = useState(false)
  const primaryAction = (
    <Button
      iconBefore={<Icon name="arrow-left" size="small" label="Turn back" />}
      onClick={() => {
        window.location.href =
          process.env.REACT_APP_BUDGET_IDENTITY_AUTHORITY_DOMAIN || '/'
      }}
    >
      Yetkiniz olan uygulamaları görmek için tıklayınız.
    </Button>
  )

  const UnauthorizedPage = () => (
    <EmptyLayout>
      <EmptyState
        title="401. Yetkisiz erişim isteği"
        description={`Bu uygulamayı görüntüleme yetkiniz bulunmamaktadır.`}
        image={{
          src: UnauthorizedPageImage,
        }}
        actions={primaryAction}
      />
    </EmptyLayout>
  )

  const returnLastUrl = () => {
    localStorage.setItem(LocalStorageItems.iamCode, params.code)
    localStorage.setItem(LocalStorageItems.iamState, params.state)
    const lastUrl = localStorage.getItem(LocalStorageItems.lastPageUrl)
    localStorage.removeItem(LocalStorageItems.lastPageUrl)
    window.location.href = lastUrl || '/'
  }

  useEffect(() => {
    setTimeout(() => {
      idService
        .get()
        .then((res: UserInfoResponse) => {
          localStorage.setItem(
            LocalStorageItems.profile_info,
            JSON.stringify(res.data)
          )
          // setTimeout(() => {
          //   returnLastUrl()
          // }, 1000)

          userService
            .authorizedPermissions()
            .then((permissionsResponse) => {
              localStorage.setItem(
                LocalStorageItems.permissions,
                JSON.stringify(permissionsResponse.data.data)
              )

              userIkService.getCurrentUser().then((resik) => {
                localStorage.setItem(
                  LocalStorageItems.currentUser,
                  JSON.stringify(resik.data)
                )
                returnLastUrl()
              })
            })
            .catch(() => {
              //returnLastUrl()
              localStorage.setItem(
                LocalStorageItems.lastPageUrl,
                window.location.href
              )
              localStorage.removeItem(
                process.env.REACT_APP_BUDGET_IDENTITY_SESSION_NAME || ''
              )
              setunautorizedApplication(true)
            })
        })
        .catch(() => {
          localStorage.setItem(
            LocalStorageItems.lastPageUrl,
            window.location.href
          )
          localStorage.removeItem(
            process.env.REACT_APP_BUDGET_IDENTITY_SESSION_NAME || ''
          )
          window.location.href = '/'
        })
    }, 1000)
  }, [params])

  return unautorizedApplication ? (
    <UnauthorizedPage />
  ) : (
    <SplashScreen>Yönlendirme yapılıyor...</SplashScreen>
  )
}

export default SuccessLogin
