import deepmerge from 'deepmerge'
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios'

import { HttpConfig, HttpInstance, HttpPromise, HttpSettings } from './types'

export default class Http implements HttpInstance {
  defaults: HttpConfig

  constructor(settings?: HttpSettings) {
    const defaults = {
      method: 'get',
      module: 'axios',
      origin: '',
      url: '',
      headers: {
        Accept: 'application/json, text/plain, */*',
      },
    }

    this.defaults = deepmerge(defaults, settings || {})
  }

  private requestAxios<T>(config: HttpConfig): HttpPromise<T> {
    const axiosInstance = axios.create()
    const axiosConfig: AxiosRequestConfig = {
      data: config.data,
      headers: config.headers,
      method: config.method,
      params: config.params,
      url: config.url,
      responseType: config.responseType,
      onUploadProgress: config.onUploadProgress,
      onDownloadProgress: config.onDownloadProgress,
    }

    return new Promise((resolve, reject) => {
      try {
        if (config.mappers && config.mappers.req && config.data) {
          axiosConfig.data = config.mappers.req(config.data)
        } else if (config.mappers && config.mappers.req && config.params) {
          axiosConfig.params = config.mappers.req(config.params)
        }
      } catch (err: any) {
        reject({
          code: 406,
          config,
          isError: false,
          name: 'Not Acceptable',
          reason: err ? err.message : 'Request data mapping error.',
        })
      }

      axiosInstance.interceptors.response.use(
        (response) => {
          return response
        },
        async (err) => {
          const { interceptors } = config

          if (interceptors && err.response.status === 401) {
            const refreshConfig = await interceptors.refresh(config)

            if (refreshConfig) {
              axiosConfig.headers = {
                ...axiosConfig.headers,
                ...refreshConfig.headers,
              }

              return axiosInstance(axiosConfig)
            }
          }

          reject({
            code: err.response.status,
            config,
            data: err.response.data,
            isError: true,
            name: err.response.statusText,
            reason: err.response.data.message,
          })
        }
      )

      axiosInstance(axiosConfig)
        .then((res: AxiosResponse<T>) => {
          try {
            let mapped = res.data

            if (config.mappers && config.mappers.res) {
              mapped = config.mappers.res(res.data)
            }

            resolve({
              code: res.status,
              config,
              data: mapped,
              headers: res.headers,
              name: res.statusText,
              request: res.request,
            })
          } catch (err: any) {
            reject({
              code: 406,
              config,
              isError: false,
              name: 'Not Acceptable',
              reason: err ? err.message : 'Response data mapping error.',
            })
          }
        })
        .catch((err: AxiosError) => {
          if (err.response) {
            reject({
              code: err.response.status,
              config,
              data: err.response.data,
              isError: true,
              name: err.response.statusText,
            })
          } else {
            reject({
              code: 503,
              config,
              isError: false,
              name: 'Service Unavailable',
            })
          }
        })
    })
  }

  request<T = any>(config: HttpConfig): HttpPromise<T> {
    const configs = deepmerge(this.defaults, config)
    configs.data = config.data

    if (config.url) {
      if (config.url.substring(0, 4) === 'http') {
        configs.url = config.url
      } else {
        configs.url = `${configs.origin}/${config.url}`
      }
    }

    if (config.data) {
      if (config.data instanceof FormData) {
        configs.headers['Content-Type'] = 'application/x-www-form-urlencoded'
      } else {
        configs.headers['Content-Type'] = 'application/json'
      }
    }

    switch (configs.module) {
      case 'axios':
        return this.requestAxios<T>(configs)
    }
  }
}
