import { ButtonGroup as AtlaskitButtonGroup } from '@atlaskit/button'

import { ButtonGroupProps } from './types'
import { getAppearance } from '../utils'

const ButtonGroup: React.FunctionComponent<ButtonGroupProps> = ({
  appearance,
  children,
}): JSX.Element => {
  return (
    <AtlaskitButtonGroup
      {...(appearance && { appearance: getAppearance(appearance) })}
    >
      {children}
    </AtlaskitButtonGroup>
  )
}

export default ButtonGroup
