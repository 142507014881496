import { IconProps } from 'packages/icon'
import { LangProps } from './types'
import {
  BillTypeEnum,
  DemandStatusEnum,
  FuturesTypeEnum,
  OfferStatusEnum,
  PaymentTypeEnum,
  ProjectBudgetTypeEnum,
  ProjectStatusEnum,
  PurchaseTypeEnum,
} from './enums'

export const currencies = [
  {
    label: 'TL',
    value: '₺',
  },
  {
    label: 'Dolar',
    value: '$',
  },
  {
    label: 'Euro',
    value: '€',
  },
]

export const DemandStatusOptions: any = [
  {
    label: 'Onay bekliyor',
    value: DemandStatusEnum.WaitingApprove,
  },
  {
    label: 'Onaylandı',
    value: DemandStatusEnum.Approved,
  },
  {
    label: 'Onaycı reddetti',
    value: DemandStatusEnum.Rejected,
  },
  {
    label: 'Satın almacı reddetti',
    value: DemandStatusEnum.RejectedByBuyer,
  },
  {
    label: 'Teklife dönüştürüldü',
    value: DemandStatusEnum.ConvertedToOffer,
  },
  {
    label: 'Revize gerekli',
    value: DemandStatusEnum.NeedRevision,
  },
]

export const DemandStatusFilters: any = [
  {
    label: 'Onay bekliyor',
    value: DemandStatusEnum.WaitingApprove,
  },
  {
    label: 'Onaylandı',
    value: DemandStatusEnum.Approved,
  },
  {
    label: 'Onaycı reddetti',
    value: DemandStatusEnum.Rejected,
  },
  {
    label: 'Satın almacı reddetti',
    value: DemandStatusEnum.RejectedByBuyer,
  },
  {
    label: 'Teklife dönüştürüldü',
    value: DemandStatusEnum.ConvertedToOffer,
  },
]

export const waitApproveDemandListFilters: any = [
  {
    label: 'Onay bekliyor',
    value: DemandStatusEnum.WaitingApprove,
  },
  {
    label: 'Onaylandı',
    value: DemandStatusEnum.Approved,
  },
  {
    label: 'Onaycı reddetti',
    value: DemandStatusEnum.Rejected,
  },
]

export const DemandStatusOptionsApproved: any = [
  {
    label: 'Onaylandı',
    value: DemandStatusEnum.Approved,
  },
  {
    label: 'Revize istendi',
    value: OfferStatusEnum.NeedRevision,
  },
  {
    label: 'Teklife dönüştürüldü',
    value: DemandStatusEnum.ConvertedToOffer,
  },
  {
    label: 'Satın almacı reddetti',
    value: DemandStatusEnum.RejectedByBuyer,
  },
]

export const DemandStatusOptionsApprovedFilters: any = [
  {
    label: 'Onaylandı',
    value: DemandStatusEnum.Approved,
  },
  {
    label: 'Revize istendi',
    value: DemandStatusEnum.NeedRevision,
  },
  {
    label: 'Satın almacı reddetti',
    value: DemandStatusEnum.RejectedByBuyer,
  },
  {
    label: 'Teklife dönüştürüldü',
    value: DemandStatusEnum.ConvertedToOffer,
  },
]

export const OfferStatusOptions: any = [
  {
    label: 'Onay bekliyor',
    value: OfferStatusEnum.WaitingApprove,
  },
  {
    label: 'Onaylandı',
    value: OfferStatusEnum.Approved,
  },
  {
    label: 'Onaycı reddetti',
    value: OfferStatusEnum.Rejected,
  },
  {
    label: 'Revize istendi',
    value: OfferStatusEnum.NeedRevision,
  },
  {
    label: 'Satın almacı onayladı',
    value: OfferStatusEnum.PurchasingConfirmed,
  },
  {
    label: 'Satın almacı reddetti',
    value: OfferStatusEnum.PurchasingRejected,
  },
]

export const BuyerOfferStatusOptions: any = [
  {
    label: 'Onaylandı',
    value: OfferStatusEnum.Approved,
  },
  {
    label: 'Revize istendi',
    value: OfferStatusEnum.NeedRevision,
  },
  {
    label: 'Satın almacı onayladı',
    value: OfferStatusEnum.PurchasingConfirmed,
  },
  {
    label: 'Satın almacı reddetti',
    value: OfferStatusEnum.PurchasingRejected,
  },
]

export const ProjectBudgetTypeOptions: any = [
  {
    label: 'Genel giderler',
    value: ProjectBudgetTypeEnum.General,
  },
  {
    label: 'Diğer',
    value: ProjectBudgetTypeEnum.Others,
  },
]

export const PaymentTypeOptions: any = [
  {
    label: 'Nakit',
    value: PaymentTypeEnum.Advance,
  },
  {
    label: 'Kredi kartı',
    value: PaymentTypeEnum.CreditCard,
  },
  {
    label: 'Vadeli',
    value: PaymentTypeEnum.Futures,
  },
]

export const FuturesTypeOptions: any = [
  {
    label: 'Ertelemeli',
    value: FuturesTypeEnum.Deferred,
  },
  {
    label: 'Taksitli',
    value: FuturesTypeEnum.Installment,
  },
]

export const ProjectStatusOptions: any = [
  {
    label: 'Aktif',
    value: ProjectStatusEnum.Active,
  },
  {
    label: 'İptal edildi',
    value: ProjectStatusEnum.Cancelled,
  },
  {
    label: 'Tamamlandı',
    value: ProjectStatusEnum.Completed,
  },
]
export const PurchaseTypeOptions: any = [
  {
    label: 'Tek seferlik',
    value: PurchaseTypeEnum.Only,
  },
  {
    label: 'Periyodik',
    value: PurchaseTypeEnum.Periodic,
  },
]
export const BillTypeOptions: any = [
  {
    label: 'E-Fatura',
    value: BillTypeEnum.Invoice,
  },
  {
    label: 'E-Arşiv',
    value: BillTypeEnum.Archive,
  },
]

export const icons: IconProps[] = [
  { label: 'activity', name: 'activity' },
  { label: 'add-circle', name: 'add-circle' },
  { label: 'add-item', name: 'add-item' },
  { label: 'add', name: 'add' },
  { label: 'addon', name: 'addon' },
  { label: 'app-access', name: 'app-access' },
  { label: 'app-switcher', name: 'app-switcher' },
  { label: 'archive', name: 'archive' },
  { label: 'arrow-down-circle', name: 'arrow-down-circle' },
  { label: 'arrow-down', name: 'arrow-down' },
  { label: 'arrow-left-circle', name: 'arrow-left-circle' },
  { label: 'arrow-left', name: 'arrow-left' },
  { label: 'arrow-right-circle', name: 'arrow-right-circle' },
  { label: 'arrow-right', name: 'arrow-right' },
  { label: 'arrow-up-circle', name: 'arrow-up-circle' },
  { label: 'arrow-up', name: 'arrow-up' },
  { label: 'attachment', name: 'attachment' },
  { label: 'audio-circle', name: 'audio-circle' },
  { label: 'audio', name: 'audio' },
  { label: 'backlog', name: 'backlog' },
  { label: 'billing-filled', name: 'billing-filled' },
  { label: 'billing', name: 'billing' },
  { label: 'board', name: 'board' },
  { label: 'book', name: 'book' },
  { label: 'bullet-list', name: 'bullet-list' },
  { label: 'calendar-filled', name: 'calendar-filled' },
  { label: 'calendar', name: 'calendar' },
  { label: 'camera-filled', name: 'camera-filled' },
  { label: 'camera-rotate', name: 'camera-rotate' },
  { label: 'camera-take-picture', name: 'camera-take-picture' },
  { label: 'camera', name: 'camera' },
  { label: 'canvas', name: 'canvas' },
  { label: 'check-circle-outline', name: 'check-circle-outline' },
  { label: 'check-circle', name: 'check-circle' },
  { label: 'check', name: 'check' },
  { label: 'checkbox-indeterminate', name: 'checkbox-indeterminate' },
  { label: 'checkbox', name: 'checkbox' },
  { label: 'chevron-down-circle', name: 'chevron-down-circle' },
  { label: 'chevron-down', name: 'chevron-down' },
  { label: 'chevron-left-circle', name: 'chevron-left-circle' },
  { label: 'chevron-left-large', name: 'chevron-left-large' },
  { label: 'chevron-left', name: 'chevron-left' },
  { label: 'chevron-right-circle', name: 'chevron-right-circle' },
  { label: 'chevron-right-large', name: 'chevron-right-large' },
  { label: 'chevron-right', name: 'chevron-right' },
  { label: 'chevron-up-circle', name: 'chevron-up-circle' },
  { label: 'chevron-up', name: 'chevron-up' },
  { label: 'child-issues', name: 'child-issues' },
  { label: 'code', name: 'code' },
  { label: 'comment', name: 'comment' },
  { label: 'component', name: 'component' },
  { label: 'copy', name: 'copy' },
  { label: 'creditcard-filled', name: 'creditcard-filled' },
  { label: 'creditcard', name: 'creditcard' },
  { label: 'cross-circle', name: 'cross-circle' },
  { label: 'cross', name: 'cross' },
  { label: 'dashboard', name: 'dashboard' },
  { label: 'decision', name: 'decision' },
  { label: 'department', name: 'department' },
  { label: 'detail-view', name: 'detail-view' },
  { label: 'discover-filled', name: 'discover-filled' },
  { label: 'discover', name: 'discover' },
  { label: 'document-filled', name: 'document-filled' },
  { label: 'document', name: 'document' },
  { label: 'documents', name: 'documents' },
  { label: 'download', name: 'download' },
  { label: 'drag-handler', name: 'drag-handler' },
  { label: 'dropbox', name: 'dropbox' },
  { label: 'edit-filled', name: 'edit-filled' },
  { label: 'edit', name: 'edit' },
  { label: 'email', name: 'email' },
  { label: 'emoji-add', name: 'emoji-add' },
  { label: 'emoji', name: 'emoji' },
  { label: 'error', name: 'error' },
  { label: 'export', name: 'export' },
  { label: 'feedback', name: 'feedback' },
  { label: 'file', name: 'file' },
  { label: 'filter', name: 'filter' },
  { label: 'flag-filled', name: 'flag-filled' },
  { label: 'folder-filled', name: 'folder-filled' },
  { label: 'folder', name: 'folder' },
  { label: 'followers', name: 'followers' },
  { label: 'following', name: 'following' },
  { label: 'googledrive', name: 'googledrive' },
  { label: 'graph-bar', name: 'graph-bar' },
  { label: 'graph-line', name: 'graph-line' },
  { label: 'gsuite', name: 'gsuite' },
  { label: 'highlights', name: 'highlights' },
  { label: 'home-circle', name: 'home-circle' },
  { label: 'home', name: 'home' },
  { label: 'image-border', name: 'image-border' },
  { label: 'image-resize', name: 'image-resize' },
  { label: 'image', name: 'image' },
  { label: 'info', name: 'info' },
  { label: 'invite-team', name: 'invite-team' },
  { label: 'issue-raise', name: 'issue-raise' },
  { label: 'issue', name: 'issue' },
  { label: 'issues', name: 'issues' },
  { label: 'label', name: 'label' },
  { label: 'lightbulb-filled', name: 'lightbulb-filled' },
  { label: 'lightbulb', name: 'lightbulb' },
  { label: 'like', name: 'like' },
  { label: 'link-filled', name: 'link-filled' },
  { label: 'link', name: 'link' },
  { label: 'list', name: 'list' },
  { label: 'location', name: 'location' },
  { label: 'lock-circle', name: 'lock-circle' },
  { label: 'lock-filled', name: 'lock-filled' },
  { label: 'lock', name: 'lock' },
  { label: 'marketplace', name: 'marketplace' },
  { label: 'mention', name: 'mention' },
  { label: 'menu-expand', name: 'menu-expand' },
  { label: 'menu', name: 'menu' },
  { label: 'mobile', name: 'mobile' },
  { label: 'more-vertical', name: 'more-vertical' },
  { label: 'more', name: 'more' },
  { label: 'notification-all', name: 'notification-all' },
  { label: 'notification-direct', name: 'notification-direct' },
  { label: 'notification', name: 'notification' },
  { label: 'office-building-filled', name: 'office-building-filled' },
  { label: 'office-building', name: 'office-building' },
  { label: 'open', name: 'open' },
  { label: 'overview', name: 'overview' },
  { label: 'page-filled', name: 'page-filled' },
  { label: 'page', name: 'page' },
  { label: 'pdf', name: 'pdf' },
  { label: 'people-group', name: 'people-group' },
  { label: 'people', name: 'people' },
  { label: 'person-circle', name: 'person-circle' },
  { label: 'person-with-circle', name: 'person-with-circle' },
  { label: 'person-with-cross', name: 'person-with-cross' },
  { label: 'person-with-tick', name: 'person-with-tick' },
  { label: 'person', name: 'person' },
  { label: 'portfolio', name: 'portfolio' },
  { label: 'preferences', name: 'preferences' },
  { label: 'premium', name: 'premium' },
  { label: 'presence-active', name: 'presence-active' },
  { label: 'presence-busy', name: 'presence-busy' },
  { label: 'presence-unavailable', name: 'presence-unavailable' },
  { label: 'question-circle', name: 'question-circle' },
  { label: 'question', name: 'question' },
  { label: 'questions', name: 'questions' },
  { label: 'queues', name: 'queues' },
  { label: 'quote', name: 'quote' },
  { label: 'radio', name: 'radio' },
  { label: 'recent', name: 'recent' },
  { label: 'redo', name: 'redo' },
  { label: 'refresh', name: 'refresh' },
  { label: 'retry', name: 'retry' },
  { label: 'roadmap', name: 'roadmap' },
  { label: 'room-menu', name: 'room-menu' },
  { label: 'schedule-filled', name: 'schedule-filled' },
  { label: 'schedule', name: 'schedule' },
  { label: 'screen', name: 'screen' },
  { label: 'search', name: 'search' },
  { label: 'select-clear', name: 'select-clear' },
  { label: 'send', name: 'send' },
  { label: 'settings', name: 'settings' },
  { label: 'share', name: 'share' },
  { label: 'ship', name: 'ship' },
  { label: 'shortcut', name: 'shortcut' },
  { label: 'sign-in', name: 'sign-in' },
  { label: 'sign-out', name: 'sign-out' },
  { label: 'sprint', name: 'sprint' },
  { label: 'star-filled', name: 'star-filled' },
  { label: 'star-large', name: 'star-large' },
  { label: 'star', name: 'star' },
  { label: 'status', name: 'status' },
  { label: 'stopwatch', name: 'stopwatch' },
  { label: 'subtask', name: 'subtask' },
  { label: 'suitcase', name: 'suitcase' },
  { label: 'switcher', name: 'switcher' },
  { label: 'table', name: 'table' },
  { label: 'task', name: 'task' },
  { label: 'trash', name: 'trash' },
  { label: 'tray', name: 'tray' },
  { label: 'undo', name: 'undo' },
  { label: 'unlink', name: 'unlink' },
  { label: 'unlock-circle', name: 'unlock-circle' },
  { label: 'unlock-filled', name: 'unlock-filled' },
  { label: 'unlock', name: 'unlock' },
  { label: 'upload', name: 'upload' },
  { label: 'user-avatar-circle', name: 'user-avatar-circle' },
  { label: 'vid-audio-muted', name: 'vid-audio-muted' },
  { label: 'vid-audio-on', name: 'vid-audio-on' },
  { label: 'vid-backward', name: 'vid-backward' },
  { label: 'vid-camera-off', name: 'vid-camera-off' },
  { label: 'vid-camera-on', name: 'vid-camera-on' },
  { label: 'vid-connection-circle', name: 'vid-connection-circle' },
  { label: 'vid-forward', name: 'vid-forward' },
  { label: 'vid-full-screen-off', name: 'vid-full-screen-off' },
  { label: 'vid-full-screen-on', name: 'vid-full-screen-on' },
  { label: 'vid-hang-up', name: 'vid-hang-up' },
  { label: 'vid-hd-circle', name: 'vid-hd-circle' },
  { label: 'vid-pause', name: 'vid-pause' },
  { label: 'vid-play', name: 'vid-play' },
  { label: 'vid-raised-hand', name: 'vid-raised-hand' },
  { label: 'vid-share-screen', name: 'vid-share-screen' },
  { label: 'vid-speaking-circle', name: 'vid-speaking-circle' },
  { label: 'vid-volume-full', name: 'vid-volume-full' },
  { label: 'vid-volume-half', name: 'vid-volume-half' },
  { label: 'vid-volume-muted', name: 'vid-volume-muted' },
  { label: 'video-circle', name: 'video-circle' },
  { label: 'video-filled', name: 'video-filled' },
  { label: 'warning', name: 'warning' },
  { label: 'watch-filled', name: 'watch-filled' },
  { label: 'watch', name: 'watch' },
  { label: 'world', name: 'world' },
]

export const urlNotRequiringAuthenticationList = ['successLogin']
